import React from 'react';
import { connect } from 'react-redux';
import './UserDataForm.scss';
import { RootState } from 'PROCUREMENTTypes';
import { ChosenProduct } from '../../redux/actions/productsList';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import { conradTheme } from './formTheme';
import { ThemeProvider } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Button from '../Button/Button';
import { ProcurementStage, setStage } from '../../redux/actions/stage';
import { getAddressAsyncAction } from '../../redux/actions/user';
import { UserReducerState } from '../../redux/reducers/user';
import { SessionReducerState } from '../../redux/reducers/session';
import { createTicketAsyncAction, CreateTicketRequestPayload } from '../../redux/actions/ticket';
import { emptySearchState } from '../../redux/actions/search';
import { withTranslation, WithTranslation } from 'react-i18next';
import { getMarket } from '../../utils/i18n';

interface UserDataProps extends WithTranslation {
  products: ChosenProduct[];
  stage: ProcurementStage;
  user: UserReducerState;
  session: SessionReducerState;
  isRequestInProgress: boolean;
  isRequestFailure: boolean;
  setStage: (stage: ProcurementStage) => void;
  fetchUserAddress: (v: void) => void;
  createTicket: (payload: CreateTicketRequestPayload) => void;
  emptySearchState: () => void;
}

type UserDataState = {
  form: UserData;
  isSubmitting?: boolean;
};

export type UserData = {
  sapCustomerNumber: string;
  salutation: string;
  title: string;
  firstName: string;
  lastName: string;
  email: string;
  street: string;
  houseNumber: string;
  zipCode: string;
  city: string;
  country: string;
  phone: string;
};

type SelectOption = {
  value: any;
  label: string;
  backendValue?: any;
};

const salutationOptions: SelectOption[] = [
  { value: 'ms_mrs', label: 'ms_mrs', backendValue: '0001' },
  { value: 'mr', label: 'mr', backendValue: '0002' },
];

const titleOptions: SelectOption[] = [
  { value: '', label: '-' },
  { value: 'dr', label: 'dr' },
  { value: 'prof', label: 'prof' },
];

const countriesOptions: SelectOption[] = [
  { backendValue: 'BE', value: 'België', label: 'België' },
  { backendValue: 'COM', value: 'Conrad.com', label: 'Conrad.com' },
  { backendValue: 'CZ', value: 'Česká republika', label: 'Česká republika' },
  { backendValue: 'DK', value: 'Danmark', label: 'Danmark' },
  { backendValue: 'DE', value: 'Deutschland', label: 'Deutschland' },
  { backendValue: 'FR', value: 'France', label: 'France' },
  { backendValue: 'HR', value: 'Hrvatska', label: 'Hrvatska' },
  { backendValue: 'IT', value: 'Italia', label: 'Italia' },
  { backendValue: 'HU', value: 'Magyarország', label: 'Magyarország' },
  { backendValue: 'NL', value: 'Nederland', label: 'Nederland' },
  { backendValue: 'AT', value: 'Österreich', label: 'Österreich' },
  { backendValue: 'PL', value: 'Polska', label: 'Polska' },
  { backendValue: 'SI', value: 'Slovenija', label: 'Slovenija' },
  { backendValue: 'SK', value: 'Slovensko', label: 'Slovensko' },
  { backendValue: 'SE', value: 'Sverige', label: 'Sverige' },
  { backendValue: 'CH', value: 'Schweiz', label: 'Schweiz' }
];

class UserDataForm extends React.PureComponent<UserDataProps, UserDataState> {
  static wasAddressRequested: boolean;
  private wasAddressAutofilled: boolean = false;

  constructor(props: UserDataProps) {
    super(props);

    this.state = {
      form: {
        sapCustomerNumber: '',
        salutation: '',
        title: '',
        firstName: '',
        lastName: '',
        email: props.session.email || '',
        street: '',
        houseNumber: '',
        zipCode: '',
        city: '',
        country: '',
        phone: ''
      }
    };
  }

  onSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (this.props.isRequestInProgress) {
      return;
    }

    this.props.createTicket({
      userData: this.state.form,
      products: this.props.products
    });

    (window as any).dataLayer.push({
      'event': 'gaEvent-specialProcurementForm',
      'category': 'SpecialProcurement',
      'action': 'Button Clicks',
      'label': 'SubmitForm',
    });
    this.props.emptySearchState();
  }

  updateField = (fieldName: string) => {
    return (e: React.ChangeEvent<HTMLInputElement>) => {
      this.setState({
        ...this.state,
        form: {
          ...this.state.form,
          [fieldName]: e.target.value
        }
      });
    };
  }

  componentDidUpdate(prevProps: UserDataProps) {
    // Request user address when he opens this form for first time
    if (!UserDataForm.wasAddressRequested &&
      prevProps.stage !== this.props.stage &&
      this.props.stage === ProcurementStage.USER_DATA_FORM
    ) {
      UserDataForm.wasAddressRequested = true;
      this.props.fetchUserAddress();
    }

    // Autofill the form if address is already fetched
    if (!this.wasAddressAutofilled && this.props.user.isSuccessfullyFetched) {
      this.wasAddressAutofilled = true;
      this.autocompleteUserData();
    }
  }

  autocompleteUserData() {
    const u = this.props.user;
    const countryOpt = this.getOptionValueByBackendValue(countriesOptions, u.country!);
    const newState = {
      ...this.state,
      form: {
        ...this.state.form,
        salutation: this.getOptionValueByBackendValue(salutationOptions, u.salutation!),
        title: u.academicTitle!,
        firstName: u.firstName!,
        lastName: u.lastName!,
        phone: u.phone!,
        sapCustomerNumber: u.sapCustomerNumber || this.props.session.sapCustomerNumber || ''
      }
    };

    // Autocomplete address only if country code exists in our options
    if (!u.country || countryOpt) {
      newState.form.street = u.street!;
      newState.form.houseNumber = u.houseNumber!;
      newState.form.zipCode = u.zip!;
      newState.form.city = u.city!;
      newState.form.country = countryOpt;
    }

    this.setState(newState);
  }

  getOptionValueByBackendValue(options: SelectOption[], backendValue: any) {
    const option = options.find(o => o.backendValue === backendValue);
    return option ? option.value : '';
  }

  salutationIsShown = (): boolean => {
    const hideInMarkets = ['hu'];
    return !hideInMarkets.includes(getMarket());
  }

  render() {
    const { form } = this.state;

    if (this.props.stage !== ProcurementStage.USER_DATA_FORM) {
      return null;
    }

    return (
      <div className="user-details-form-ui clearfix">
        <ThemeProvider theme={conradTheme}>
          <form className="user-data-form" onSubmit={this.onSubmit}>
            <h3 className="title">
              {this.props.t('personal_data', 'Ihre persönlichen Daten')}
            </h3>
            <Grid container spacing={2}>
              <Grid item sm={5} xs={12}>
                <TextField
                  label={this.props.t('customer_number_readonly', 'Kundennummer (schreibgeschützt)')}
                  value={form.sapCustomerNumber}
                  fullWidth
                  onChange={this.updateField('sapCustomerNumber')}
                  variant="outlined"
                  size="small"
                  InputProps={{ readOnly: form.sapCustomerNumber.length > 0 }}
                  disabled={form.sapCustomerNumber.length < 1}
                />
              </Grid>
              {this.salutationIsShown() &&
                <Grid item sm={4} xs={12}>
                  <TextField
                    select
                    required
                    InputLabelProps={{ required: false }}
                    label={this.props.t('salutation', 'Anrede')}
                    value={form.salutation}
                    fullWidth
                    onChange={this.updateField('salutation')}
                    variant="outlined"
                    size="small"
                    SelectProps={{ IconComponent: ExpandMoreIcon }}
                  >{salutationOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {this.props.t(option.label, option.value)}
                    </MenuItem>
                  ))}
                  </TextField>
                </Grid>
              }
              <Grid item sm={3} xs={12}>
                <TextField
                  select
                  fullWidth
                  label={this.props.t('title', 'Titel') + ' ' + this.props.t('optional', '(optional)')}
                  value={form.title}
                  onChange={this.updateField('title')}
                  variant="outlined"
                  size="small"
                  SelectProps={{ IconComponent: ExpandMoreIcon }}
                >
                  {titleOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {this.props.t(option.label, option.value)}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item sm={5} xs={12}>
                <TextField
                  required
                  InputLabelProps={{ required: false }}
                  label={this.props.t('first_name', 'Vorname')}
                  value={form.firstName}
                  fullWidth
                  onChange={this.updateField('firstName')}
                  variant="outlined"
                  size="small"
                />
              </Grid>
              <Grid item sm={7} xs={12}>
                <TextField
                  required
                  InputLabelProps={{ required: false }}
                  label={this.props.t('last_name', 'Nachname')}
                  value={form.lastName}
                  fullWidth
                  onChange={this.updateField('lastName')}
                  variant="outlined"
                  size="small"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  InputLabelProps={{ required: false }}
                  type="email"
                  label={this.props.t('email', 'Email')}
                  value={form.email}
                  fullWidth
                  onChange={this.updateField('email')}
                  variant="outlined"
                  size="small"
                />
              </Grid>
              <Grid item sm={8} xs={12}>
                <TextField
                  required
                  InputLabelProps={{ required: false }}
                  label={this.props.t('street', 'Straße')}
                  value={form.street}
                  fullWidth
                  onChange={this.updateField('street')}
                  variant="outlined"
                  size="small"
                />
              </Grid>
              <Grid item sm={4} xs={12}>
                <TextField
                  required
                  InputLabelProps={{ required: false }}
                  label={this.props.t('number', 'Hausnummer')}
                  value={form.houseNumber}
                  fullWidth
                  onChange={this.updateField('houseNumber')}
                  variant="outlined"
                  size="small"
                />
              </Grid>
              <Grid item sm={4} xs={12}>
                <TextField
                  required
                  InputLabelProps={{ required: false }}
                  label={this.props.t('postal_code', 'Postleizahl')}
                  value={form.zipCode}
                  fullWidth
                  onChange={this.updateField('zipCode')}
                  variant="outlined"
                  size="small"
                />
              </Grid>
              <Grid item sm={8} xs={12}>
                <TextField
                  required
                  InputLabelProps={{ required: false }}
                  label={this.props.t('location', 'Ort')}
                  value={form.city}
                  fullWidth
                  onChange={this.updateField('city')}
                  variant="outlined"
                  size="small"
                />
              </Grid>
              <Grid item sm={5} xs={12}>
                <TextField
                  select
                  required
                  InputLabelProps={{ required: false }}
                  label={this.props.t('country', 'Land')}
                  value={form.country}
                  fullWidth
                  onChange={this.updateField('country')}
                  variant="outlined"
                  size="small"
                  SelectProps={{ IconComponent: ExpandMoreIcon }}
                >{countriesOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
                </TextField>
              </Grid>
              <Grid item sm={7} xs={12}>
                <TextField
                  label={this.props.t('phone_number', 'Telefon') + ' ' + this.props.t('optional', '(optional)')}
                  value={form.phone}
                  fullWidth
                  onChange={this.updateField('phone')}
                  variant="outlined"
                  size="small"
                />
              </Grid>
            </Grid>
            {this.props.isRequestFailure && <div className="form-error">
              {this.props.t('error_technical', 'Ihre Bestellung konnte leider nicht erfolgreich erstellt werden. Bitte versuchen Sie es erneut.')}
            </div>}
            <div className="clearfix">
              <Button
                type="submit"
                color="yellow"
                className="submit-request-btn"
                disabled={this.props.isRequestInProgress}
              >{this.props.t('send', 'Abschicken')}</Button>
            </div>
          </form>
        </ThemeProvider>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState) => {
  return {
    products: state.productsList,
    stage: state.stage,
    user: state.user,
    session: state.session,
    isRequestInProgress: state.ticket.isRequestInProgress,
    isRequestFailure: !!state.ticket.isError,
  };
};

const mapActions = {
  setStage,
  fetchUserAddress: getAddressAsyncAction.request,
  createTicket: createTicketAsyncAction.request,
  emptySearchState: emptySearchState
};

export default connect(mapStateToProps, mapActions)(withTranslation()(UserDataForm));
