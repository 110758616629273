import { RootState } from "PROCUREMENTTypes";
import { Maybe } from "tsmonad";
import { SearchEntries, SearchTypes, Product, SearchButtons, Actions } from "../../data/Search";
import { Action } from "redux";

export const getSearchEntries = (state: RootState): SearchEntries =>
  state.search;

export const getSearchingFlag = (state: RootState): Maybe<boolean> => Maybe.maybe(getSearchEntries(state).searching);

export const getSearchType = (state: RootState): Maybe<SearchTypes> => Maybe.maybe(getSearchEntries(state).searchType);

export const isSearchActive = (state: RootState): Maybe<boolean> => Maybe.maybe(getSearchEntries(state).searchType ? true : false);

export const getEanOrCategoryValue = (state: RootState): Maybe<string> => Maybe.maybe(getSearchEntries(state).eanOrCategoryValue);

export const isEan = (
  state: RootState
): Maybe<boolean> =>
  getEanOrCategoryValue(state).bind(query =>
    Maybe.maybe(!isNaN(query as any))
  );

export const getBrandValue = (state: RootState): Maybe<string> => Maybe.maybe(getSearchEntries(state).brandValue);

export const getDescriptionValue = (state: RootState): Maybe<string> => Maybe.maybe(getSearchEntries(state).descriptionValue);

export const getAmountValue = (state: RootState): Maybe<number> => Maybe.maybe(getSearchEntries(state).amountValue);

export const getProduct = (state: RootState): Maybe<Product> => Maybe.maybe(getSearchEntries(state).product);

export const getButtons = (state: RootState): Maybe<SearchButtons> => Maybe.maybe(getSearchEntries(state).buttons);

export const getAnimation = (state: RootState): Maybe<number> => Maybe.maybe(getSearchEntries(state).animation);

export const getMessage = (state: RootState): Maybe<string> => Maybe.maybe(getSearchEntries(state).message);

export const getHeadline = (state: RootState): Maybe<string> => Maybe.maybe(getSearchEntries(state).headline);

export const getLabel = (state: RootState): Maybe<string> => Maybe.maybe(getSearchEntries(state).label);

export const getActions = (state: RootState): Maybe<Actions> => Maybe.maybe(getSearchEntries(state).actions);

export const getSuccess = (state: RootState): Maybe<boolean> => Maybe.maybe(getSearchEntries(state).success);

export const getBack = (state: RootState): Maybe<Action> => Maybe.maybe(getSearchEntries(state).back);
