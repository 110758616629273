import * as React from "react";
import "./SearchBase.scss";

export type SearchBaseProps = {
  headline: string;
  children: React.ReactNode;
};

const SearchBase: React.FC<SearchBaseProps> = props => {
  const { headline, children } = props;
  return (
    <div className="search-base-ui">
      <div className="outer-container">
        <h2 className="headline">{headline}</h2>
        <div className="inner-container">
          {children}
        </div>
      </div>
    </div>
  );
};

export default SearchBase;
