import React from 'react';
import { connect } from 'react-redux';

import './Footer.scss';
import { RootState } from 'PROCUREMENTTypes';
import { withTranslation, WithTranslation } from 'react-i18next';
import { getMarketLink } from '../../utils/i18n';

interface IFooterState {
}

class Footer extends React.PureComponent<WithTranslation, IFooterState> {

  render() {

    return (
      <div className="footer-ui">
        <div className="security-text">
          <div className="security-point">
            <img src="icons/haken-usage.svg" alt="" className="checked" />
            <img src="icons/haken-usage-grey.svg" alt="" className="checked-grey" />
            {this.props.t('data_protection', 'Datenschutz')}
          </div>
          <div className="security-point">
            <img src="icons/haken-usage.svg" alt="" className="checked" />
            <img src="icons/haken-usage-grey.svg" alt="" className="checked-grey" />
            {this.props.t('secure_payment_methods', 'Sichere Zahlungsmittel')}
          </div>
          <div className="security-point">
            <img src="icons/haken-usage.svg" alt="" className="checked" />
            <img src="icons/haken-usage-grey.svg" alt="" className="checked-grey" />
            {this.props.t('ssl_encryption', 'SSL-Verschlüsselung')}
          </div>
          <div className="security-point">
            <img src="icons/haken-usage.svg" alt="" className="checked" />
            <img src="icons/haken-usage-grey.svg" alt="" className="checked-grey" />
            {this.props.t('verified_secure_code', 'Verified Visa & Mastercard Secure Code')}
          </div>
        </div>
        <div className="footer-links">
          <div className="footer-links-block">
            <a href={getMarketLink('terms')}>
              {this.props.t('terms_conditions', 'AGB')}
            </a>
            <span className="separator">|</span>
            <a href={getMarketLink('imprint')}>
              {this.props.t('disclaimer', 'Impressum')}
            </a>
            <span className="separator">|</span>
            <a href={getMarketLink('privacy')}>
              {this.props.t('data_protection', 'Datenschutz')}
            </a>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): WithTranslation => {
  return {
  } as WithTranslation;
};

export default connect(mapStateToProps)((withTranslation()(Footer)));
