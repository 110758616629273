import { takeEvery, put, select, all } from 'redux-saga/effects';
import { RootState } from 'PROCUREMENTTypes';
import { combineLatest } from '../helpers';
import {
  getProductDataEanConradSearchAsyncAction,
  getProductDataConradProductAsyncAction,
  getProductDataConradMarketplaceAsyncAction,
  openSearchResultForm,
  addToCart,
  openSearchAmountForm,
  getProductDataEanEanSearchAsyncAction,
  getProductDataEanApiGeniusAsyncAction,
  submitAmountForm
} from '../../actions/search';
import { getType } from 'typesafe-actions';
import { Maybe } from 'tsmonad';
import { setSearchedProductState } from '../../actions/searchedProduct';
import { getSearchedProductProductNumber, getSearchedProductMarketplace, getSearchedProductOfferNumber } from '../../../selectors/searchedProduct';
import { getMarket, getProductLink } from '../../../utils/i18n';
import i18next from 'i18next';

export function* watchGetProductConradSuccessSuccess() {

  yield combineLatest([getProductDataEanConradSearchAsyncAction.success, getProductDataConradProductAsyncAction.success], handleGetProductConradSuccessSuccess);
}

function* handleGetProductConradSuccessSuccess(actions: [ReturnType<typeof getProductDataEanConradSearchAsyncAction.success>, ReturnType<typeof getProductDataConradProductAsyncAction.success>]): Generator {
  const action = actions.find(({ type }) => type === getType(getProductDataEanConradSearchAsyncAction.success)) as ReturnType<typeof getProductDataEanConradSearchAsyncAction.success>;
  const ean = action.meta;
  const { productShortInformation, brand: _brand, shortProductNumber } = (typeof action !== 'undefined' && action.payload[0]) || {};
  const { shortDescription, articleGroupName, mainImage } = productShortInformation || {};
  const { imageUrl } = mainImage || {};
  const { displayName } = _brand || {};

  const title = Maybe.maybe(shortDescription).valueOr('');
  const brand = Maybe.maybe(displayName).valueOr('');
  const category = Maybe.maybe(articleGroupName).valueOr('');
  const image = Maybe.maybe(imageUrl).valueOr('icons/no-image.png');
  const productNumber = Maybe.maybe(shortProductNumber).valueOr('');
  const url = getProductLink(productNumber);

  yield put(setSearchedProductState({ image, brand, title, category, productNumber, url }));
  yield put(getProductDataConradMarketplaceAsyncAction.request(productNumber, ean));
}

export function* watchGetProductConradSuccessFailure() {
  yield combineLatest([getProductDataEanConradSearchAsyncAction.success, getProductDataConradProductAsyncAction.failure], handleGetProductConradSuccessFailure);
}

function* handleGetProductConradSuccessFailure(actions: [ReturnType<typeof getProductDataEanConradSearchAsyncAction.success>, ReturnType<typeof getProductDataConradProductAsyncAction.failure>]): Generator {
  const action = actions.find(
    ({ type }) => type === getType(getProductDataEanConradSearchAsyncAction.success)
    ) as ReturnType<typeof getProductDataEanConradSearchAsyncAction.success>;
  const { productShortInformation, brand: _brand, shortProductNumber } = (typeof action !== 'undefined' && action.payload[0]) || {};
  const { shortDescription, articleGroupName, mainImage } = productShortInformation || {};
  const { imageUrl } = mainImage || {};
  const { displayName } = _brand || {};

  const title = Maybe.maybe(shortDescription).valueOr('');
  const brand = Maybe.maybe(displayName).valueOr('');
  const category = Maybe.maybe(articleGroupName).valueOr('');
  const image = Maybe.maybe(imageUrl).valueOr('icons/no-image.png');
  const productNumber = Maybe.maybe(shortProductNumber).valueOr('');
  const url = getProductLink(productNumber);

  yield put(setSearchedProductState({ image, brand, title, category, productNumber, url }));
  const openSearchResultFormAction = () => openSearchResultForm(
    {
      text: i18next.t(['add_to_cart', 'In den Warenkorb legen']),
      color: 'yellow',
      icon: true,
      isAddToCart: true
    },
    {
      text: i18next.t(['request_quotationservice', 'Anfrage an Beschaffungsservice']),
      color: 'grey'
    },
    addToCart(productNumber),
    openSearchAmountForm(submitAmountForm(), openSearchResultFormAction),
    i18next.t(['error_not_available', 'Der von Ihnen gesuchte Artikel kann jetzt direkt bei <strong>conrad.de</strong> bestellt werden.'], { conradmarket: `conrad.${getMarket()}` }),
    '',
    true
  );
  yield put(openSearchResultFormAction());
}

export function* watchGetProductConradFailureSuccess() {
  yield combineLatest(
    [getProductDataEanConradSearchAsyncAction.failure, getProductDataConradProductAsyncAction.success], handleGetProductConradFailureSuccess
  );
}

function* handleGetProductConradFailureSuccess(actions: [ReturnType<typeof getProductDataEanConradSearchAsyncAction.failure>, ReturnType<typeof getProductDataConradProductAsyncAction.success>]): Generator {
  const action = actions.find(
    ({ type }) => type === getType(getProductDataConradProductAsyncAction.success)
  ) as ReturnType<typeof getProductDataConradProductAsyncAction.success>;
  const ean = action.meta;
  const { title: _title, brand: _brand, image: _image, productNumber, marketplace: _marketplace } = action.payload || {};
  const title = Maybe.maybe(_title).valueOr('');
  const brand = Maybe.maybe(_brand).valueOr('');
  const image = Maybe.maybe(_image).valueOr('icons/no-image.png');
  const url = getProductLink(productNumber);
  const marketplace = Maybe.maybe(_marketplace).valueOr(false);

  yield put(setSearchedProductState({ image, brand, title, productNumber, url, marketplace }));
  yield put(getProductDataConradMarketplaceAsyncAction.request(productNumber, ean));
}

export function* watchGetProductConradFailureFailure() {
  yield combineLatest(
    [getProductDataEanConradSearchAsyncAction.failure, getProductDataConradProductAsyncAction.failure], handleGetProductConradFailureFailure
  );
}

function* handleGetProductConradFailureFailure(actions: [ReturnType<typeof getProductDataEanConradSearchAsyncAction.failure>, ReturnType<typeof getProductDataConradProductAsyncAction.failure>]): Generator {
  const ean = actions[0].meta;
  yield all([
    put(getProductDataEanEanSearchAsyncAction.request(ean)),
    put(getProductDataEanApiGeniusAsyncAction.request(ean))
  ]);
}

export function* watchGetProductDataConradMarketplaceSuccess() {
  yield takeEvery(getProductDataConradMarketplaceAsyncAction.success, handleGetProductDataConradMarketplaceSuccess);
}

function* handleGetProductDataConradMarketplaceSuccess(action: ReturnType<typeof getProductDataConradMarketplaceAsyncAction.success>): Generator {
  const { priceAndAvailabilityFacadeResponse: { products: { product } } } = action.payload || {};
  const { offers: { offer } } = (Array.isArray(product) ? product[0] : product) || {};
  const { articleId: productNumber, offerId: offerNumber } = (Array.isArray(offer) ? offer[0] : offer) || {};
  yield put(setSearchedProductState({ productNumber, offerNumber, marketplace: true }));
  const openSearchResultFormAction = () => openSearchResultForm(
    {
      text: i18next.t(['add_to_cart', 'In den Warenkorb legen']),
      color: 'yellow',
      icon: true,
      isAddToCart: true
    },
    {
      text: i18next.t(['request_quotationservice', 'Anfrage an Beschaffungsservice']),
      color: 'grey'
    },
    addToCart(productNumber, offerNumber),
    openSearchAmountForm(submitAmountForm(), openSearchResultFormAction),
    i18next.t(['success_available', 'Der von Ihnen gesuchte Artikel ist im <strong>Conrad Marketplace</strong> verfügbar und kann auf diesem Weg bestellt werden.']),
    '',
    false
  );
  yield put(openSearchResultFormAction());
}

export function* watchGetProductDataConradMarketplaceFailure() {
  yield takeEvery(getProductDataConradMarketplaceAsyncAction.failure, handleGetProductDataConradMarketplaceFailure);
}

function* handleGetProductDataConradMarketplaceFailure(action: ReturnType<typeof getProductDataConradMarketplaceAsyncAction.failure>): Generator {
  const state = yield select();
  const productNumber = getSearchedProductProductNumber(state as RootState).valueOr('');
  const marketplace = getSearchedProductMarketplace(state as RootState).valueOr(false);
  const offerNumber = getSearchedProductOfferNumber(state as RootState).valueOr('');

  if (marketplace) {
    const openSearchResultFormAction = () => openSearchResultForm(
      {
        text: i18next.t(['add_to_cart', 'In den Warenkorb legen']),
        color: 'yellow',
        icon: true,
        isAddToCart: true
      },
      {
        text: i18next.t(['request_quotationservice', 'Anfrage an Beschaffungsservice']),
        color: 'grey'
      },
      addToCart(productNumber, offerNumber),
      openSearchAmountForm(submitAmountForm(), openSearchResultFormAction),
      i18next.t(['success_available', 'Der von Ihnen gesuchte Artikel ist im <strong>Conrad Marketplace</strong> verfügbar und kann auf diesem Weg bestellt werden.']),
      '',
      false
    );
    yield put(openSearchResultFormAction());
  } else {
    const openSearchResultFormAction = () => openSearchResultForm(
      {
        text: i18next.t(['add_to_cart', 'In den Warenkorb legen']),
        color: 'yellow',
        icon: true,
        isAddToCart: true
      },
      {
        text: i18next.t(['request_quotationservice', 'Anfrage an Beschaffungsservice']),
        color: 'grey'
      },
      addToCart(productNumber),
      openSearchAmountForm(submitAmountForm(), openSearchResultFormAction),
      i18next.t(['error_not_available', 'Der von Ihnen gesuchte Artikel kann jetzt direkt bei <strong>conrad.de</strong> bestellt werden.'], { conradmarket: `conrad.${getMarket()}` }),
      '',
      true
    );
    yield put(openSearchResultFormAction());
  }
}
