import { createReducer } from 'typesafe-actions';
import { Reducer } from 'redux';
import { emptySearchState,
  setSearchEanOrCategoryValue,
  openSearchEanOrCategoryForm,
  openSearchBrandForm,
  openSearchDescriptionForm,
  setSearchBrandValue,
  setSearchDescriptionValue,
  setSearchAmountValue,
  setSearchState,
  enableSearchingFlag,
  disableSearchingFlag } from '../../actions/search';
import { SearchEntries, EANORCATEGORY, BRAND, DESCRIPTION } from '../../../data/Search';

export type SearchReducerState = SearchEntries;
const initialState: SearchReducerState = {
  searchType: EANORCATEGORY
};

const handleEnableSearchingFlag = (
  state: SearchReducerState,
  action: ReturnType<typeof enableSearchingFlag>
): SearchReducerState => ({
  ...state,
  searching: true
});

const handleDisableSearchingFlag = (
  state: SearchReducerState,
  action: ReturnType<typeof disableSearchingFlag>
): SearchReducerState => ({
  ...state,
  searching: false
});

const handleSetSearchEanOrCategoryValue = (
  state: SearchReducerState,
  action: ReturnType<typeof setSearchEanOrCategoryValue>
): SearchReducerState => {
  const {value} = action.payload;
  return {
    ...state,
    eanOrCategoryValue: value
  };
};

const handleSetSearchBrandValue = (
  state: SearchReducerState,
  action: ReturnType<typeof setSearchBrandValue>
): SearchReducerState => {
  const {value} = action.payload;
  return {
    ...state,
    brandValue: value
  };
};

const handleSetSearchDescriptionValue = (
  state: SearchReducerState,
  action: ReturnType<typeof setSearchDescriptionValue>
): SearchReducerState => {
  const {value} = action.payload;
  return {
    ...state,
    descriptionValue: value
  };
};

const handleSetSearchAmountValue = (
  state: SearchReducerState,
  action: ReturnType<typeof setSearchAmountValue>
): SearchReducerState => {
  const {value} = action.payload;
  return {
    ...state,
    amountValue: value
  };
};

const handleOpenSearchEanOrCategoryForm = (
  state: SearchReducerState,
  action: ReturnType<typeof openSearchEanOrCategoryForm>
): SearchReducerState => ({
  ...state,
  searchType: EANORCATEGORY
});

const handleOpenSearchBrandForm = (
  state: SearchReducerState,
  action: ReturnType<typeof openSearchBrandForm>
): SearchReducerState => ({
  ...state,
  searchType: BRAND
});

const handleOpenSearchDescriptionForm = (
  state: SearchReducerState,
  action: ReturnType<typeof openSearchDescriptionForm>
): SearchReducerState => ({
  ...state,
  searchType: DESCRIPTION
});

const handleSetSearchState = (
  state: SearchReducerState,
  action: ReturnType<typeof setSearchState>
): SearchReducerState => {
  const { searchState } = action.payload;
  return {
    ...state,
    ...searchState
  };
};

const handleEmptySearchState = (
  state: SearchReducerState,
  action: ReturnType<typeof emptySearchState>
): SearchReducerState => {
  return {};
};

const search: Reducer<SearchReducerState> = createReducer(
  initialState
)
  .handleAction(openSearchEanOrCategoryForm, handleOpenSearchEanOrCategoryForm)
  .handleAction(openSearchBrandForm, handleOpenSearchBrandForm)
  .handleAction(openSearchDescriptionForm, handleOpenSearchDescriptionForm)
  .handleAction(setSearchEanOrCategoryValue, handleSetSearchEanOrCategoryValue)
  .handleAction(setSearchBrandValue, handleSetSearchBrandValue)
  .handleAction(setSearchDescriptionValue, handleSetSearchDescriptionValue)
  .handleAction(setSearchAmountValue, handleSetSearchAmountValue)
  .handleAction(setSearchState, handleSetSearchState)
  .handleAction(emptySearchState, handleEmptySearchState)
  .handleAction(enableSearchingFlag, handleEnableSearchingFlag)
  .handleAction(disableSearchingFlag, handleDisableSearchingFlag);

export default search;
