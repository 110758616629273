import React from 'react';
import './Button.scss';

type IButtonProps = {
    children?: any;
    className?: string;
    disabled?: boolean;
    type?: 'button'|'submit'|'reset'; // It's 'button' by default
    color?: 'yellow'|'grey'; // It's blue by default
    size?: 'large';
    onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
    attrs?: {[attr: string]: any};
};

export default function Button(props: IButtonProps) {
    const {
        className = '',
        color = '',
        type = 'button',
        size = '',
        onClick,
    } = props;

    return (
        <button
            type={type}
            onClick={e => {onClick && onClick(e)}}
            disabled={Boolean(props.disabled)}
            {...props.attrs}
            className={`button-ui ${className} ${color} ${size ? 'size-' + size : ''}`}
        >
            {props.children}
        </button>
    );
}