import { connect } from 'react-redux';
import Search from './Search';
import { Dispatch } from 'redux';
import { RootState } from 'PROCUREMENTTypes';
import { getSearchType,
  getEanOrCategoryValue,
  getBrandValue,
  getDescriptionValue,
  getAmountValue,
  getButtons,
  getAnimation,
  getMessage,
  getHeadline,
  getLabel,
  getActions,
  getSuccess,
  getSearchingFlag } from '../../selectors/search';
import { SearchButtons, CONFIRM, REJECT, Actions } from '../../data/Search';
import { setSearchEanOrCategoryValue,
  submitEanOrCategoryForm,
  setSearchBrandValue,
  submitBrandForm,
  setSearchDescriptionValue,
  submitDescriptionForm,
  setSearchAmountValue,
  submitAmountForm,
  openSearchBrandForm,
  openSearch,
  emptySearchState } from '../../redux/actions/search';
import { Action } from 'typesafe-actions';
import { getSearchedProductImage,
  getSearchedProductBrand,
  getSearchedProductTitle,
  getSearchedProductCategory,
  getSearchedProductDescription,
  getSearchedProductProductNumber,
  getSearchedProductUrl,
  getSearchedProductOfferNumber,
  getSearchedProductMarketplace } from '../../selectors/searchedProduct';

export const defaultButtons: SearchButtons = {
  [CONFIRM]: {
    text: '',
    color: ''
  },
  [REJECT]: {
    text: '',
    color: ''
  }
};

const defaultActions: Actions = {
  [CONFIRM]: openSearch(),
  [REJECT]: emptySearchState()
};

const mapStateToProps = (state: RootState, props: any) => {
  return {
    searching: getSearchingFlag(state).valueOr(false),
    searchType: getSearchType(state),
    eanOrCategoryValue: getEanOrCategoryValue(state).valueOr(''),
    brandValue: getBrandValue(state).valueOr(''),
    descriptionValue: getDescriptionValue(state).valueOr(''),
    amountValue: getAmountValue(state).valueOr(1),
    buttons: getButtons(state).valueOr(defaultButtons),
    animation: getAnimation(state).valueOr(0),
    image: getSearchedProductImage(state).valueOr(''),
    brand: getSearchedProductBrand(state).valueOr(''),
    title: getSearchedProductTitle(state).valueOr(''),
    category: getSearchedProductCategory(state).valueOr(''),
    description: getSearchedProductDescription(state).valueOr(''),
    productNumber: getSearchedProductProductNumber(state).valueOr(''),
    url: getSearchedProductUrl(state).valueOr(''),
    offerNumber: getSearchedProductOfferNumber(state).valueOr(''),
    marketplace: getSearchedProductMarketplace(state).valueOr(false),
    message: getMessage(state).valueOr(''),
    headline: getHeadline(state).valueOr(''),
    label: getLabel(state).valueOr(''),
    actions: getActions(state).valueOr(defaultActions),
    success: getSuccess(state).valueOr(false),
    props
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onChangeSearchEanOrCategory: (value: string): any =>
    dispatch(setSearchEanOrCategoryValue(value)),
  onSubmitEanOrCategoryForm: (): any =>
    dispatch(submitEanOrCategoryForm()),
  onChangeSearchBrand: (value: string): any =>
    dispatch(setSearchBrandValue(value)),
  onSubmitBrandForm: (): any =>
    dispatch(submitBrandForm()),
  onChangeSearchDescription: (value: string): any =>
    dispatch(setSearchDescriptionValue(value)),
  onSubmitDescriptionForm: (): any =>
    dispatch(submitDescriptionForm()),
  onChangeSearchAmount: (value: number): any =>
    dispatch(setSearchAmountValue(value)),
  onSubmitAmountForm: (): any =>
    dispatch(submitAmountForm()),
  onBackToBrandForm: (): any =>
    dispatch(openSearchBrandForm()),
  onConfirm: (action: Action): any => dispatch(action),
  onReject: (action: Action): any => dispatch(action)
});

export default connect(mapStateToProps, mapDispatchToProps)(
  Search
);
