import { takeEvery, call, getContext, put } from 'redux-saga/effects';
import { createTicketAsyncAction } from '../../actions/ticket';
import { buildCreateTicketBody } from './buildCreateTicketBody';
import {setStage, ProcurementStage} from '../../actions/stage';
import { removeAllProducts } from '../../actions/productsList';

export function* watchCreateTicket() {
    yield takeEvery(createTicketAsyncAction.request, createTicket);
}

function* createTicket(action: ReturnType<typeof createTicketAsyncAction.request>): Generator {
    try {
        const getCreateTicketUrl: any = yield getContext('getCreateTicketUrl');
        let url: any = yield getCreateTicketUrl();
        const headers: any = {'Content-Type': 'application/json'};
        const body = buildCreateTicketBody(action.payload.userData, action.payload.products);

        const response: any = yield call(fetch, url, {
            method: 'POST',
            headers,
            body: JSON.stringify(body)
        });

        if (response.status === 201) {
            yield put(removeAllProducts());
            yield put(setStage(ProcurementStage.TICKET_CREATED));
            yield put(createTicketAsyncAction.success(response));
            return;
        }

        yield put(createTicketAsyncAction.failure(response));
    } catch (err) {
        yield put(createTicketAsyncAction.failure(err));
    }
}
