import React from 'react';
import { connect } from 'react-redux';
import './App.scss';
import { RootState } from 'PROCUREMENTTypes';
import { Route, HashRouter, Switch } from 'react-router-dom';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import Procurement from './components/Procurement/Procurement';
import Login from './components/Login/Login';
import { getSessionAsyncAction } from './redux/actions/session';
import { WithTranslation, withTranslation } from 'react-i18next';

interface IAppProps extends WithTranslation {
  isAccessAllowed?: boolean;
  getSession: typeof getSessionAsyncAction.request;
}

class App extends React.PureComponent<IAppProps> {

  componentDidMount() {
    document.title = this.props.t('eProcurement', 'Beschaffungsservice');
    this.props.getSession();
  }

  render() {

    // Wait until user session is fetched
    if (typeof this.props.isAccessAllowed === 'undefined') {
      return null;
    }

    return (
      <HashRouter>
        <Switch>
          <Route path="/login" component={Login} />
          <PrivateRoute isLoggedIn={this.props.isAccessAllowed} exact={true} path="/" component={Procurement} />
        </Switch>
      </HashRouter>
    );
  }
}

const mapStateToProps = (state: RootState) => {
  return {
    isAccessAllowed: state.session.isAccessAllowed
  };
};

const mapDispatchToProps = {
  getSession: getSessionAsyncAction.request
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(App));
