import { takeEvery, call, getContext, put, select, all } from 'redux-saga/effects';
import { getProductDataEanConradSearchAsyncAction,
  getProductDataEanApiGeniusAsyncAction,
  getProductDataEanEanSearchAsyncAction,
  submitEanOrCategoryForm,
  openSearchBrandForm,
  submitBrandForm,
  submitDescriptionForm,
  openSearchResultForm,
  closeSearch,
  emptySearchState,
  setSearchState,
  openSearchDescriptionForm,
  openSearchAmountForm,
  submitAmountForm,
  setSearchProduct,
  openSearch,
  openSearchEanOrCategoryForm,
  addToCart,
  getProductDataConradMarketplaceAsyncAction,
  getProductDataConradProductAsyncAction,
  openSearchFailureResultForm
 } from '../../actions/search';
import { getEanOrCategoryValue, isEan, getBrandValue, getDescriptionValue, getAmountValue } from '../../../selectors/search';
import { RootState } from 'PROCUREMENTTypes';
import { CONFIRM, REJECT, RESULT, FAILURE, AMOUNT } from '../,,/../../../data/Search';
import when from 'when-switch';
import { addProduct } from '../../actions/productsList';
import { getSapCustomerNumber } from '../../../selectors/session';
import { combineLatest } from '../helpers';
import { getSearchedProductImage, getSearchedProductCategory, getSearchedProductBrand, getSearchedProductTitle, getSearchedProductDescription } from '../../../selectors/searchedProduct';
import { setSearchedProductState } from '../../actions/searchedProduct';
import { Action } from 'typesafe-actions';
import { getCartDirectUrl } from '../../../utils/i18n';
import i18next from 'i18next';

export function* watchGetProductDataEanConradSearchRequest() {
  yield takeEvery(getProductDataEanConradSearchAsyncAction.request, handleGetProductEanDataConradSearchRequest);
}

export function* watchGetProductDataConradProductRequest() {
  yield takeEvery(getProductDataConradProductAsyncAction.request, handleGetProductDataConradProductRequest);
}

export function* watchGetProductDataConradMarketplaceRequest() {
  yield takeEvery(getProductDataConradMarketplaceAsyncAction.request, handleGetProductDataConradMarketplaceRequest);
}

export function* watchGetProductDataEanApiGenius() {
  yield takeEvery(getProductDataEanApiGeniusAsyncAction.request, handleGetProductDataEanApiGenius);
}

export function* watchGetProductDataEanEanSearch() {
  yield takeEvery(getProductDataEanEanSearchAsyncAction.request, handleGetProductDataEanEanSearch);
}

export function* watchSubmitEanOrCategoryForm() {
  yield takeEvery(submitEanOrCategoryForm, handleSubmitEanOrCategoryForm);
}

export function* watchSubmitBrandForm() {
  yield takeEvery(submitBrandForm, handleSubmitBrandForm);
}

export function* watchSubmitDescriptionForm() {
  yield takeEvery(submitDescriptionForm, handleSubmitDescriptionForm);
}

export function* watchSubmitAmountForm() {
  yield takeEvery(submitAmountForm, handleSubmitAmountForm);
}

export function* watchSetSearchProduct() {
  yield takeEvery(setSearchProduct, handleSetSearchProduct);
}

export function* watchOpenSearchResultForm() {
  yield takeEvery(openSearchResultForm, handleOpenSearchResultForm);
}

export function* watchOpenSearchAmountForm() {
  yield takeEvery(openSearchAmountForm, handleOpenSearchAmountForm);
}

export function* watchOpenSearchFailureResultForm() {
  yield takeEvery(openSearchFailureResultForm, handleOpenSearchFailureResultForm);
}

export function* watchOpenSearch() {
  yield takeEvery(openSearch, handleOpenSearch);
}

export function* watchAddToCart() {
  yield takeEvery(addToCart, handleAddToCart);
}

export function* watchCloseSearch() {
  yield takeEvery(closeSearch, handleCloseSearch);
}

function* handleCloseSearch(action: ReturnType<typeof closeSearch>): Generator {
  const state = yield select();
  const ean = getEanOrCategoryValue(state as RootState).valueOr('');
  const key = Math.floor(100000000 + Math.random() * 900000000);
  const amount = getAmountValue(state as RootState).valueOr(1);
  const quantity = typeof amount === 'string' ? parseInt(amount, 10) : amount;
  const title = getSearchedProductTitle(state as RootState).valueOr('');
  const brand = getSearchedProductBrand(state as RootState).valueOr('');
  const category = getSearchedProductCategory(state as RootState).valueOr('');
  const image = getSearchedProductImage(state as RootState).valueOr('icons/no-image.png');
  const description = getSearchedProductDescription(state as RootState).valueOr('');
  yield put(addProduct
    ({key, brand, title: title || category , quantity, maxUnitPrice: 0, description, ean: isEan(state as RootState).valueOr(false) ? ean : undefined, image: image !== 'icons/no-image.png' ? image : undefined})
  );
  yield put(emptySearchState());
}

function* handleAddToCart(action: ReturnType<typeof addToCart>): Generator {
  const {productNumber = '', offerNumber = ''} = action.payload;
  const cartRedirectUrl = getCartDirectUrl(productNumber, offerNumber);

  yield window.open(cartRedirectUrl, '_blank');
}

function* handleOpenSearch(action: ReturnType<typeof openSearch>): Generator {
  yield put(openSearchEanOrCategoryForm());
}

export function* watchGetProductDataEanSuccessSuccess() {
  yield combineLatest([getProductDataEanEanSearchAsyncAction.success, getProductDataEanApiGeniusAsyncAction.success], handleGetProductDataEanSuccessSuccess);
}

export function* watchGetProductDataEanSuccessFailure() {
  yield combineLatest([getProductDataEanEanSearchAsyncAction.success, getProductDataEanApiGeniusAsyncAction.failure], handleGetProductDataEanSuccessFailure);
}

export function* watchGetProductDataEanFailureSuccess() {
  yield combineLatest([getProductDataEanEanSearchAsyncAction.failure, getProductDataEanApiGeniusAsyncAction.success], handleGetProductDataEanFailureSuccess);
}

export function* watchGetProductDataEanFailureFailure() {
  yield combineLatest([getProductDataEanEanSearchAsyncAction.failure, getProductDataEanApiGeniusAsyncAction.failure], handleGetProductDataEanFailureFailure);
}

function* handleGetProductDataEanSuccessSuccess(actions: [ReturnType<typeof getProductDataEanEanSearchAsyncAction.success>, ReturnType<typeof getProductDataEanApiGeniusAsyncAction.success>]): Generator {
  const product = {
    title: '',
    brand: '',
    category: '',
    image: 'icons/no-image.png'
  };
  actions.map(action => {
    return when(action.type)
    .is('@bs/GET_PRODUCT_DATA_EAN_EAN_SEARCH_SUCCESS [EVENT]', () => {
      const {categoryName = ''} = action.payload;
      product.category = categoryName !== 'Unknown' ? categoryName : '';
    })
    .is('@bs/GET_PRODUCT_DATA_EAN_API_GENIUS_SUCCESS [EVENT]', () => {
      const {items: {title = '', brand = '', images = 'icons/no-image.png'}} = action.payload;
      product.title = title;
      product.brand = brand;
      product.image = images[0] || 'icons/no-image.png';
    })
    .else([]);
  });

  yield put(setSearchedProductState(product));
  yield put(openSearchAmountForm(submitAmountForm(), () => openSearchEanOrCategoryForm()));
}

function* handleGetProductDataEanSuccessFailure(actions: [ReturnType<typeof getProductDataEanEanSearchAsyncAction.success>, ReturnType<typeof getProductDataEanApiGeniusAsyncAction.failure>]): Generator {
  const product = {
    title: '',
    brand: '',
    category: '',
    image: 'icons/no-image.png'
  };
  actions.map(action => {
    return when(action.type)
    .is('@bs/GET_PRODUCT_DATA_EAN_EAN_SEARCH_SUCCESS [EVENT]', () => {
      const {name = '', categoryName = ''} = action.payload;
      product.title = name !== 'Unknown' ? name : '';
      product.category = categoryName !== 'Unknown' ? categoryName : '';
    })
    .else([]);
  });

  yield put(setSearchedProductState(product));
  yield put(openSearchAmountForm(submitAmountForm(), () => openSearchEanOrCategoryForm()));
}

function* handleGetProductDataEanFailureSuccess(actions: [ReturnType<typeof getProductDataEanEanSearchAsyncAction.failure>, ReturnType<typeof getProductDataEanApiGeniusAsyncAction.success>]): Generator {
  const product = {
    title: '',
    brand: '',
    category: '',
    image: 'icons/no-image.png'
  };
  actions.map(action => {
    return when(action.type)
    .is('@bs/GET_PRODUCT_DATA_EAN_API_GENIUS_SUCCESS [EVENT]', () => {
      const {items: {title = '', brand = '', category = '', images = 'icons/no-image.png'}} = action.payload;
      product.title = title;
      product.brand = brand;
      product.category = category;
      product.image = images[0] || 'icons/no-image.png';
    })
    .else([]);
  });

  yield put(setSearchedProductState(product));
  yield put(openSearchAmountForm(submitAmountForm(), () => openSearchEanOrCategoryForm()));
}

function* handleGetProductDataEanFailureFailure(actions: [ReturnType<typeof getProductDataEanEanSearchAsyncAction.failure>, ReturnType<typeof getProductDataEanApiGeniusAsyncAction.failure>]): Generator {
  yield put(openSearchFailureResultForm(
    {
      text: i18next.t(['close_search_form', 'Suchformular schließen']),
    },
    {
      text: i18next.t(['new_search', 'Neue Suche']),
    },
    emptySearchState(),
    openSearch(),
    i18next.t(['error_not_found', 'Der Artikel, den Sie suchen, wurde nicht gefunden.']),
    ''
  ));
}

function* handleGetProductEanDataConradSearchRequest(action: ReturnType<typeof getProductDataEanConradSearchAsyncAction.request>): Generator {
  const ean = action.payload;
  try {
    const createConradProductEanUrl: any = yield getContext('createConradProductEanUrl');
    const url: any = yield createConradProductEanUrl(ean);
    const response: any = yield call(fetch, url);
    if (response.ok) {
      const data: any = yield response.json();
      yield put(getProductDataEanConradSearchAsyncAction.success(data, ean));
      return;
    }
    yield put(getProductDataEanConradSearchAsyncAction.failure(response, ean));
  } catch (err) {
    yield put(getProductDataEanConradSearchAsyncAction.failure(err, ean));
  }
}

function* handleOpenSearchResultForm(action: ReturnType<typeof openSearchResultForm>): Generator {
  const { confirmButton, rejectButton, confirmAction, rejectAction, message, headline, success } = action.payload;
  yield put(setSearchState({
    searchType: RESULT,
    buttons: {
      [CONFIRM]: confirmButton,
      [REJECT]: rejectButton
    },
    actions: {
      [CONFIRM]: confirmAction,
      [REJECT]: rejectAction
    },
    message,
    headline,
    success
  }));
}

function* handleOpenSearchAmountForm(action: ReturnType<typeof openSearchAmountForm>): Generator {
  const { confirmAction, rejectFunction } = action.payload;
  const rejectAction: Action = rejectFunction();
  yield put(setSearchState({
    searchType: AMOUNT,
    actions: {
      [CONFIRM]: confirmAction,
      [REJECT]: rejectAction
    }
  }));
}

function* handleOpenSearchFailureResultForm(action: ReturnType<typeof openSearchFailureResultForm>): Generator {
  const { confirmButton, rejectButton, confirmAction, rejectAction, message, headline } = action.payload;
  yield put(setSearchState({
    searchType: FAILURE,
    buttons: {
      [CONFIRM]: confirmButton,
      [REJECT]: rejectButton
    },
    actions: {
      [CONFIRM]: confirmAction,
      [REJECT]: rejectAction
    },
    message,
    headline
  }));
}

function* handleSubmitEanOrCategoryForm(action: ReturnType<typeof submitEanOrCategoryForm>): Generator {
  const state = yield select();
  const ean = getEanOrCategoryValue(state as RootState).valueOr('');
  isEan(state as RootState).valueOr(false) ? yield all([
    put(getProductDataConradProductAsyncAction.request(ean)),
    put(getProductDataEanConradSearchAsyncAction.request(ean))
  ]) : yield put(openSearchBrandForm());
}

function* handleSubmitBrandForm(action: ReturnType<typeof submitBrandForm>): Generator {
  const state = yield select();
  const category = getEanOrCategoryValue(state as RootState).valueOr('');
  const brand = getBrandValue(state as RootState).valueOr('');
  yield put(setSearchedProductState({image: 'icons/no-image.png', category, brand}));
  yield put(openSearchDescriptionForm());
}

function* handleSubmitDescriptionForm(action: ReturnType<typeof submitDescriptionForm>): Generator {
  const state = yield select();
  const category = getEanOrCategoryValue(state as RootState).valueOr('');
  const brand = getBrandValue(state as RootState).valueOr('');
  const description = getDescriptionValue(state as RootState).valueOr('');
  yield put(setSearchedProductState({image: 'icons/no-image.png', category, brand, description}));
  yield put(openSearchAmountForm(submitAmountForm(), () => openSearchDescriptionForm()));
}

function* handleSubmitAmountForm(action: ReturnType<typeof submitAmountForm>): Generator {
  const openSearchResultFormAction = () => openSearchResultForm(
    {
      text: i18next.t(['forward', 'Weiter']),
      color: 'blue'
    },
    {
      text: i18next.t(['back', 'Zurück']),
      color: 'grey'
    },
    closeSearch(),
    openSearchAmountForm(submitAmountForm(), openSearchResultFormAction),
    i18next.t(['success_info_stored', 'Wir haben folgenden Informationen zu Ihrem Artikel gespeichert.']),
    i18next.t(['thank_you', 'Vielen Dank!']),
    false
  );
  yield put(openSearchResultFormAction());
}

function* handleSetSearchProduct(action: ReturnType<typeof setSearchProduct>): Generator {
  const { image, title, brand, category, productNumber, url, offerNumber, marketplace } = action.payload;
  yield put(setSearchState({
    product: {
      image,
      title,
      brand,
      category,
      productNumber,
      url,
      offerNumber,
      marketplace
    }
  }));
}

function* handleGetProductDataEanEanSearch(action: ReturnType<typeof getProductDataEanEanSearchAsyncAction.request>): Generator {
  const ean = action.payload;
  try {
    const createEanSearchEanUrl: any = yield getContext('createEanSearchEanUrl');
    const url: any = yield createEanSearchEanUrl(ean);
    const response: any = yield call(fetch, url);
    if (response.ok) {
      const data: any = yield response.json();
      if (data[0].hasOwnProperty('error')) {
        yield put(getProductDataEanEanSearchAsyncAction.failure(data[0], ean));
        return;
      }
      yield put(getProductDataEanEanSearchAsyncAction.success(data[0], ean));
      return;
    }
    yield put(getProductDataEanEanSearchAsyncAction.failure(response, ean));
  } catch (err) {
    yield put(getProductDataEanEanSearchAsyncAction.failure(err, ean));
  }
}

function* handleGetProductDataEanApiGenius(action: ReturnType<typeof getProductDataEanApiGeniusAsyncAction.request>): Generator {
  const ean = action.payload;
  try {
    const createApiGeniusSearchEanUrl: any = yield getContext('createApiGeniusSearchEanUrl');
    const url: any = yield createApiGeniusSearchEanUrl(ean);
    const response: any = yield call(fetch, url);
    if (response.ok) {
      const data: any = yield response.json();
      if (!data.success) {
        yield put(getProductDataEanApiGeniusAsyncAction.failure(data, ean));
        return;
      }
      yield put(getProductDataEanApiGeniusAsyncAction.success(data, ean));
      return;
    }
    yield put(getProductDataEanApiGeniusAsyncAction.failure(response, ean));
  } catch (err) {
    yield put(getProductDataEanApiGeniusAsyncAction.failure(err, ean));
  }
}

function* handleGetProductDataConradProductRequest(action: ReturnType<typeof getProductDataConradProductAsyncAction.request>): Generator {
  const ean = action.payload;
  try {
    const createConradSearchEanUrl: any = yield getContext('createConradSearchEanUrl');
    const url: any = yield createConradSearchEanUrl();

    const body = JSON.stringify({
      'query': ean,
      'enabledFeatures':['and_filters', 'filters_without_values', 'query_relaxation'], 'disabledFeatures':[], 'globalFilter':[], 'facetFilter':[],
      'sort':[{'field':'_score','order':'desc'}],
      'from':0,
      'size':30,
      'facets':[],
      'partialThreshold':10,
      'partialQueries':3,
      'partialQuerySize':6
    });
    const response: any = yield call(fetch, url, {method: 'POST', headers: {'Accept': 'application/json', 'content-type': 'application/json'}, body, redirect: 'follow'});
    if (response.ok) {
      const data: any = yield response.json();
      const { hits } = data;
      if (hits.length > 0) {
        const { marketplaceSellers, image, brand: {name: brand}, title, productId: productNumber } = hits[0];
        if (!marketplaceSellers.some(({id}: any) => id === 'CE001')) {
          yield put(getProductDataConradProductAsyncAction.success({title, brand, image, productNumber, marketplace: true}, ean));
          return;
        } else {
          yield put(getProductDataConradProductAsyncAction.failure(response, ean));
        }
      } else {
        yield put(getProductDataConradProductAsyncAction.failure(response, ean));
      }
    } else {
      yield put(getProductDataConradProductAsyncAction.failure(response, ean));
    }
  } catch (err) {
    yield put(getProductDataConradProductAsyncAction.failure(err, ean));
  }
}

function* handleGetProductDataConradMarketplaceRequest(action: ReturnType<typeof getProductDataConradMarketplaceAsyncAction.request>): Generator {
  const productNumber = action.payload;
  const ean = action.meta;
  const state = yield select();
  const customerNumber = getSapCustomerNumber(state as RootState).valueOr('');
  try {
    const createConradMarketplaceProductUrl: any = yield getContext('createConradMarketplaceProductUrl');
    const url: any = yield createConradMarketplaceProductUrl(customerNumber);

    const body = JSON.stringify({
      'ns:inputArticleItemList': {
        '#namespaces': {
          'ns':'http://www.conrad.de/ccp/basit/service/article/priceandavailabilityservice/api'
        },
        'articles': [{'calculatePrice':'true', 'checkAvailability':'true', 'findExclusions':'true', 'articleID': productNumber, 'insertCode':'62'}]
      }
    });
    const response: any = yield call(fetch, url, {method: 'POST', headers: {'Accept': 'application/json', 'content-type': 'application/json'}, body, redirect: 'follow'});
    if (response.ok) {
      const data: any = yield response.json();
      const {priceAndAvailabilityFacadeResponse: {globalStatus}} = data;
      if (globalStatus !== 'ERROR') {
        yield put(getProductDataConradMarketplaceAsyncAction.success(data, ean));
        return;
      } else {
        yield put(getProductDataConradMarketplaceAsyncAction.failure(response, ean));
      }
    }
    yield put(getProductDataConradMarketplaceAsyncAction.failure(response, ean));
  } catch (err) {
    yield put(getProductDataConradMarketplaceAsyncAction.failure(err, ean));
  }
}
