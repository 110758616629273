import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { getDefaultLanguage } from './utils/i18n';
import i18nEN from './locale/i18n/en.json';
import i18nDE from './locale/i18n/de.json';
import i18nFR from './locale/i18n/fr.json';
import i18nCZ from './locale/i18n/cz.json';
import i18nDK from './locale/i18n/dk.json';
import i18nHR from './locale/i18n/hr.json';
import i18nHU from './locale/i18n/hu.json';
import i18nIT from './locale/i18n/it.json';
import i18nNL from './locale/i18n/nl.json';
import i18nPL from './locale/i18n/pl.json';
import i18nSE from './locale/i18n/se.json';
import i18nSL from './locale/i18n/sl.json';
import i18nSK from './locale/i18n/sk.json';
import i18nDE_ch from './locale/i18n/de-ch.json';
import i18nFR_ch from './locale/i18n/fr-ch.json';
const defaultLanguage = getDefaultLanguage();

i18n
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    lng: defaultLanguage,
    fallbackLng: [defaultLanguage, 'en', 'de'],
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: i18nEN
      },
      'de-CH': {
        translation: i18nDE_ch
      },
      de: {
        translation: i18nDE
      },
      'fr-CH': {
        translation: i18nFR_ch
      },
      fr: {
        translation: i18nFR
      },
      cz: {
        translation: i18nCZ
      },
      dk: {
        translation: i18nDK
      },
      hr: {
        translation: i18nHR
      },
      hu: {
        translation: i18nHU
      },
      it: {
        translation: i18nIT
      },
      nl: {
        translation: i18nNL
      },
      pl: {
        translation: i18nPL
      },
      se: {
        translation: i18nSE
      },
      sl: {
        translation: i18nSL
      },
      sk: {
        translation: i18nSK
      },
    }
  });

export default i18n;
