import * as React from "react";
import ReactHtmlParser from 'react-html-parser';
import "./InputField.scss";

export type InputFieldDataProps = {
    label?: string;
    value: any;
    type?: 'text' | 'number';
    autoFocus?: boolean;
    error?: boolean;
    errorMessage?: string;
};

export type InputFieldEventProps = {
    onChange: (value: any) => void;
};

export type InputFieldProps = InputFieldDataProps & InputFieldEventProps;

const InputField: React.FC<InputFieldProps> = props => {
    const {label, value, onChange, type = 'text', autoFocus = false, error, errorMessage} = props;

    return (
        <div className="input-ui">
            <label className="label">{typeof label === 'undefined' ? label : ReactHtmlParser(label)}</label>
            <input
                className={`input ${error ? 'error' : ''}`}
                type={type}
                value={value}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => onChange(event.target.value)}
                autoFocus={autoFocus}
             />
             <div className={`error-message ${error ? 'active' : ''}`}>{errorMessage}</div>
        </div>
    );
};

export default InputField;
