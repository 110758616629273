import * as React from "react";
import "./SearchBrand.scss";
import InputField from "../InputField/InputField";
import ButtonUI from "../Button/Button";
import SearchBase from "../SearchBase/SearchBase";
import ProgressBar from "../ProgressBar/ProgressBar";
import { useTranslation } from 'react-i18next';

export type SearchBrandDataProps = {
  brandValue: string;
};

export type SearchBrandEventProps = {
  onChangeSearchBrand: (query: string) => void;
  onSubmitBrandForm: () => void;
};

export type SearchBrandProps = SearchBrandDataProps & SearchBrandEventProps;

const SearchBrand: React.FC<SearchBrandProps> = props => {
  const { brandValue, onChangeSearchBrand, onSubmitBrandForm } = props;
  const { t } = useTranslation();

  const onSubmit = (e: React.FormEvent) => {
    // Prevent form submitting to avoid refresh on any other page except of "/?#/"
    e.preventDefault();
    onSubmitBrandForm();
  };

  return (
    <SearchBase headline={t('which_brand', 'Von welcher Marke?')}>
      <div className="search-brand-ui">
        <form onSubmit={onSubmit}>
          <InputField
            label={t('company_manufacturer_brand', 'Firma / Hersteller / Fabrikat')}
            autoFocus
            value={brandValue}
            onChange={(value: string) =>
              onChangeSearchBrand(value)
            }
          />
          <div className="button-side">
            <ButtonUI type="submit" disabled={brandValue.length < 1}>
              {t('forward', 'Weiter')}
            </ButtonUI>
          </div>
          <ProgressBar size={33.3} />
      </form>
      </div>
    </SearchBase>
  );
};

export default SearchBrand;
