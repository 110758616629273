import { combineReducers } from 'redux';

import sample from '../redux/reducers/sample';
import session from '../redux/reducers/session';
import productsList from '../redux/reducers/productsList';
import stage from '../redux/reducers/stage';
import search from '../redux/reducers/search';
import user from '../redux/reducers/user';
import ticket from '../redux/reducers/ticket';
import searchedProduct from '../redux/reducers/searchedProduct';
import language from '../redux/reducers/language';
import market from '../redux/reducers/market'

const rootReducer = () =>
  combineReducers({
    sample,
    session,
    productsList,
    stage,
    search,
    user,
    ticket,
    searchedProduct,
    language,
    market
  });

export default rootReducer;
