import { createMuiTheme, Theme } from '@material-ui/core/styles';

export const conradTheme: Theme = createMuiTheme({
    overrides: {
        MuiFormControl: {
            root: {
                marginTop: '20px',
                fontSize: '13px',
                '& legend': {
                    display: 'none'
                }
            }
        },
        MuiInputBase: {
            root: {
                fontSize: '13px',
            },
            input: {
                lineHeight: '19px',
                height: '19px',
            }
        },
        MuiInputLabel: {
            root: {
                fontSize: '13px',
                color: '#666666',
            },
            outlined: {
                '&.MuiInputLabel-marginDense': {
                    transform: 'translate(14px, 15px) scale(1)',
                },
                '&.MuiInputLabel-marginDense.MuiInputLabel-shrink': {
                    color: '#666666',
                    transform: 'translate(1px, -15px) scale(0.84)'
                }
            }
        },
        MuiOutlinedInput: {
            root: {
                borderRadius: '0',
                border: 'solid 1px #979797',
                backgroundColor: '#F4F4F4',
                '&.Mui-focused': {
                    border: 'solid 1px #0099DE',
                    backgroundColor: '#F2FAFD',
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderWidth: '0'
                }
            },
            notchedOutline: {
                borderWidth: '0'
            }
        },
        MuiSelect: {
            select: {
                '&:focus': {
                    backgroundColor: 'transparent'
                }
            }
        },
        MuiMenuItem: {
            root: {
                fontSize: '13px'
            }
        }
	}
});