import { take } from 'redux-saga/effects';

function createCombineLatest() {
    return function* combineLatest(actionTypes: any, saga: any) {
      let actions: any = {};
      while (true) {
        const action = yield take(actionTypes);
        actions[action.type] = action;

        if (allActionsReady(actions, actionTypes)) {
          yield saga(actionTypes.map((t: any) => actions[t]));
          actions = {};
        }
      }
    };
}

function allActionsReady(actions: any, actionTypes: any) {
    let eanCodes: string[] = [];
    Object.values(actions).map(({meta}: any) => eanCodes.push(meta));

    return Object.keys(actions).length === actionTypes.length && eanCodes.every(ean => ean === eanCodes[0]);
}

export const combineLatest = createCombineLatest();