import * as React from 'react';
import { useTranslation } from 'react-i18next';
import './ProductDetails.scss';

export type ProductDetailsDataProps = {
    image: string;
    brand: string;
    title: string;
    category: string;
    description: string;
    productNumber: string;
    offerNumber: string;
    marketplace: boolean;
    amountValue?: number;
    url?: string;
};

export type ProductDetailsEventProps = {};

export type ProductDetailsProps = ProductDetailsDataProps & ProductDetailsEventProps;

const ProductDetails: React.FC<ProductDetailsProps> = props => {
  const { brand, title, image, category, url, marketplace, amountValue } = props;
  const { t } = useTranslation();

  return (
      <div className="product-details-ui">
        <div className="image">
          <img src={typeof image !== 'undefined' && image.length > 0 ? image : 'icons/no-image.png'} alt=""/>
        </div>
        <div className="content">
          <div className="details">
            <p>
              {brand.length > 0 ? <span className="brand">{brand}</span> : null}
              {marketplace ? <span className="marketplace" /> : null}
            </p>
            <p>{title}</p>
            <p>{category}</p>
          </div>
          {
            typeof amountValue !== 'undefined' && amountValue > 0 ?
            <div className="amount">
              <p><strong>{amountValue}</strong></p>
              <p><span>
                {t('piece', 'Stück')}
              </span></p>
            </div> :
            null
          }
          {
            typeof url !== 'undefined' && url.length > 0 ?
            <div className="url">
              <a href={url} target="_blank" rel="noopener noreferrer">
                {t('view_product', 'Produkt ansehen')}
              </a>
            </div> :
            null
          }
        </div>
      </div>
  );
};

export default ProductDetails;

