import { createReducer } from 'typesafe-actions';
import { Reducer } from 'redux';
import { setExpressToProduct, addProduct, ChosenProduct, removeAllProducts, removeProductFromList } from '../../actions/productsList';

export type ProductsListReducerState = ChosenProduct[];


const initialState: ProductsListReducerState = [];

const productsList: Reducer<ProductsListReducerState> = createReducer(initialState)
    .handleAction(removeProductFromList, handleRemoveProductFromList)
    .handleAction(addProduct, handleAddProduct)
    .handleAction(setExpressToProduct, handleSetExpressToProduct)
    .handleAction(removeAllProducts, handleRemoveAllProducts);

function handleRemoveProductFromList(
    state: ProductsListReducerState,
    action: ReturnType<typeof removeProductFromList>
): ProductsListReducerState {
    return state.filter(p => p.key !== action.payload);
}

function handleAddProduct(
    state: ProductsListReducerState,
    action: ReturnType<typeof addProduct>
): ProductsListReducerState {
    const newProducts = [
        ...state
    ];
    newProducts.push(action.payload);
    return newProducts;
}

function handleSetExpressToProduct(
    state: ProductsListReducerState,
    action: ReturnType<typeof setExpressToProduct>
): ProductsListReducerState {

    return state.map(p => {
        if (p.key === action.payload.key) {
            return {...p, express: action.payload.express};
        } else {
            return p;
        }
    });
}

function handleRemoveAllProducts(
    state: ProductsListReducerState,
    action: ReturnType<typeof removeAllProducts>
): ProductsListReducerState {
    return [];
}

export default productsList;