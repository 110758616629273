import React from 'react';
import './ProgressBar.scss';

type IProgressBarProps = {
    size: number;
};

export default function ProgressBar(props: IProgressBarProps) {
    const { size } = props;

    return (
        <div className={`progress-bar-ui size-${size}`}>
            <div className="animation" style={{width: `${size}%`}}></div>
        </div>
    );
}