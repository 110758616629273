import { UserData } from '../../../components/UserDataForm/UserDataForm';
import { ChosenProduct } from '../../actions/productsList';
import { escape } from 'html-escaper';
import { getBrandId, getLocale } from '../../../utils/i18n';
import i18next from 'i18next';

export function buildCreateTicketBody(user: UserData, products: ChosenProduct[]) {
  const locale = getLocale();
  const salutation = i18next.t([user.salutation, user.salutation]);
  const title = i18next.t([user.title, user.title]);
  const userIdentifier = user.sapCustomerNumber || `${salutation} ${title} ${user.firstName} ${user.lastName}`;
  const brand = process.env.NODE_ENV === 'production' ? { brand_id: getBrandId() } : {}; // Enterprise only

  return {
    ticket: {
      subject: `${i18next.t(['eProcurement', 'Beschaffungsservice'])} - ${userIdentifier}`,
      comment: {
        html_body: getComment(user, products)
      },
      type: 'task',
      tags: ['eProcurement', 'Beschaffungsservice'],
      requester: {
        locale_id: locale,
        name: `${user.firstName} ${user.lastName}`,
        email: user.email
      },
      status: 'new',
      ...brand
    }
  };
}

function getComment(user: UserData, products: ChosenProduct[]): string {
  const comment: string[] = [];
  comment.push(`
        <p>${i18next.t(['hello', 'Hallo'])} ${user.firstName}!</p>
        <p>${i18next.t(['thank_you_order', 'Haben Sie vielen Dank für Ihre Bestellung. Wir werden Ihre Anfrage umgehend bearbeiten und melden uns in Kürze bei Ihnen.'])}</p>
        <h3>${i18next.t(['your_request', 'Die Anfrage'])}</h3>
        <table style="width: 100%; border-style:solid; border-width:1px; border-color: grey" cellspacing="0">
            <thead>
                <tr>
                    <th>${i18next.t(['quantity', 'Stückzahl'])}</th>
                    <th></th>
                    <th>${i18next.t(['product', 'Produkt'])}</th>
                </tr>
            </thead>
            <tbody>
    `);

  products.forEach(p => {
    const description = p.description ? wrapLinks(escape(p.description)) : undefined;
    comment.push(`
            <tr>
                <td>${escape(String(p.quantity))}&nbsp;x</td>
                <td>
                    ${addIfNotEmpty('<img src="', p.image, '" style="width: 80px"/>')}
                </td>
                <td>
                    ${addIfNotEmpty('<div><b>', p.title, '</b></div>')}
                    ${addIfNotEmpty('<div>EAN: ', p.ean, '</div>')}
                    ${addIfNotEmpty(`<div>${i18next.t(['brand', 'Marke:'])} `, p.brand, '</div>')}
                    ${addIfNotEmpty('<div>', description, '</div>', true)}
                </td>
            </tr>
        `);
  });

  comment.push(`
        </tbody></table><br/>
        <h3>${i18next.t(['personal_data', 'Persönliche Daten'])}</h3>
        <p>
            ${addIfNotEmpty(`${i18next.t(['customer_number', 'Kundennummer'])}: `, user.sapCustomerNumber, '<br/>')}
            ${i18next.t(['email', 'Email'])}: ${escape(user.email)}
        </p>
        <p>
            ${escape(i18next.t([user.salutation]))} ${escape(i18next.t([user.title]))} ${escape(user.firstName)} ${escape(user.lastName)}<br/>
            ${escape(user.street)} ${escape(user.houseNumber)}<br/>
            ${escape(user.zipCode)} ${escape(user.city)}<br/>
            ${escape(user.country)}
        </p>
        ${addIfNotEmpty(`<p>${i18next.t(['phone_number', 'Telefon'])}: `, user.phone, '</p>')}
        <hr/><br/>
    `);
  return comment.join('');
}

function addIfNotEmpty(s: string, value: any, s2: string = '', isAlreadyEscaped?: boolean): string {
  if (value) {
    return s + (isAlreadyEscaped ? value : escape(String(value))) + s2;
  }
  return '';
}

function wrapLinks(str: string) {
  return str.replace(/\bhttps?:\/\/\S+/gi, '<a href="$&" target="_blank">$&</a>');
}
