import React from 'react';
import './Login.scss';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { RootState } from 'PROCUREMENTTypes';
import Layout from '../Layout/Layout';
import { withTranslation, WithTranslation } from 'react-i18next';

interface ILoginProps extends RouteComponentProps, WithTranslation {
    isLoggedIn?: boolean;
    isAccessAllowed?: boolean;
    loginUrl?: string;
    isErrorHappenedWhileSessionRequest?: boolean;
}

export class Login extends React.PureComponent<ILoginProps> {

    componentDidMount() {
        this.checkUserSession();
    }

    componentDidUpdate() {
        this.checkUserSession();
    }

    checkUserSession() {
        const {loginUrl, isLoggedIn, isAccessAllowed, history} = this.props;

        if (isAccessAllowed) {
            history.push('/');
            return;
        }

        if (isLoggedIn === false && loginUrl) {
            window.location.href = loginUrl;
        }
    }

    render() {
        const {isLoggedIn, isAccessAllowed, isErrorHappenedWhileSessionRequest} = this.props;
        const isB2cUser = (isLoggedIn && !isAccessAllowed);
        const isErrorShown = (isB2cUser || isErrorHappenedWhileSessionRequest);

        return (
            <Layout>
                <div className="login-ui">
                    <div className="page-container">
                        {isErrorShown && <div className="grey-container">
                            {isB2cUser ?
                              this.props.t('availability', 'Der Beschaffungsservice ist nur für registrierte Geschäftskunden verfügbar.')
                              : ''}
                            {isErrorHappenedWhileSessionRequest && <div className="error">
                              {this.props.t('technical_problems', 'Leider gab es ein technisches Problem. Bitte versuchen Sie es zu einem späteren Zeitpunkt noch einmal.')}
                            </div> }
                        </div>}
                    </div>
                </div>
            </Layout>
        );
    }
}

const mapStateToProps = (state: RootState) => {
	return {
        isLoggedIn: state.session.isLoggedIn,
        isAccessAllowed: state.session.isAccessAllowed,
        loginUrl: state.session.loginUrl,
        isErrorHappenedWhileSessionRequest: state.session.isErrorHappenedWhileSessionRequest
	};
};

export default connect(mapStateToProps)(withTranslation()(Login));
