import { createReducer } from 'typesafe-actions';
import { Reducer } from 'redux';
import { getSessionAsyncAction } from '../../actions/session';

export type SessionReducerState = {
    readonly isLoggedIn?: boolean;
    readonly email?: string;
    readonly uid?: string;
    readonly sapCustomerNumber?: string;
    readonly loginUrl?: string;
    readonly isAccessAllowed?: boolean;
    readonly isErrorHappenedWhileSessionRequest?: boolean;
};

type SessionResponse = {
    psub: string;
    email: string;
    sapCustomerNumber: string;
    customFields: {
        customerGroupSapCode: string
    }
};

const initialState: SessionReducerState = {};

const session: Reducer<SessionReducerState> = createReducer(initialState)
    .handleAction(getSessionAsyncAction.success, handleGetSessionSuccess)
    .handleAction(getSessionAsyncAction.failure, handleGetSessionFailure);

function handleGetSessionSuccess(
    state: SessionReducerState,
    action: ReturnType<typeof getSessionAsyncAction.success>
): SessionReducerState {
    const b2cGroupSapCodes = ['100', '108'];
    const session: SessionResponse = action.payload;
    const isB2B = b2cGroupSapCodes.indexOf(session.customFields.customerGroupSapCode) === -1;

    return {
        ...state,
        isLoggedIn: true,
        email: session.email,
        uid: session.psub,
        sapCustomerNumber: session.sapCustomerNumber,
        isAccessAllowed: isB2B
    };
}

function handleGetSessionFailure(
    state: SessionReducerState,
    action: ReturnType<typeof getSessionAsyncAction.failure>
): SessionReducerState {
    let loginUrl: string|undefined;
    let isErrorHappenedWhileSessionRequest = true;
    const loginDisabled = process.env['REACT_APP_DISABLE_LOGIN'] === 'true';

    if (action.payload instanceof Response) {
        loginUrl = action.payload.headers.get('Location') || undefined;
        isErrorHappenedWhileSessionRequest = false;
    }

    return {
        ...state,
        isLoggedIn: loginDisabled,
        isAccessAllowed: loginDisabled,
        loginUrl,
        isErrorHappenedWhileSessionRequest
    };
}

export default session;
