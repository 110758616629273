import { createReducer } from 'typesafe-actions';
import { Reducer } from 'redux';
import { Language, setLanguage } from '../../actions/language';
import { getDefaultLanguage } from '../../../utils/i18n';

const initialState: Language = getDefaultLanguage();

const language: Reducer<Language> = createReducer(initialState).handleAction(setLanguage, handleSetLanguage);

function handleSetLanguage(
  state: Language,
  action: ReturnType<typeof setLanguage>
): Language {
  return action.payload;
}

export default language;
