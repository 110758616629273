import React from 'react';
import { Route, Redirect } from 'react-router-dom';

interface IProtectedPageProps {
    isLoggedIn?: boolean;
    [prop: string]: any;
}

export default function PrivateRoute({ isLoggedIn, ...props }: IProtectedPageProps): any {
    return isLoggedIn
        ? <Route { ...props } />
        : <Redirect to="/login" />;
}