import { takeEvery, call, getContext, put } from 'redux-saga/effects';
import { getSessionAsyncAction } from '../../actions/session';

export function* watchGetSession() {
    yield takeEvery(getSessionAsyncAction.request, getSession);
}

function* getSession(action: ReturnType<typeof getSessionAsyncAction.request>): Generator {
    try {
        const createConradSessionUrl: any = yield getContext('createConradSessionUrl');
        const url: any = yield createConradSessionUrl();
        const response: any = yield call(fetch, url, { credentials: 'include' });
        if (response.ok) {
            const data = yield response.json();
            yield put(getSessionAsyncAction.success(data));
            return;
        }

        yield put(getSessionAsyncAction.failure(response));
    } catch (err) {
        yield put(getSessionAsyncAction.failure(err));
    }
}
