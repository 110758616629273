import { createReducer } from 'typesafe-actions';
import { Reducer } from 'redux';
import { ProductEntries } from '../../../data/Product';
import { setSearchedProductState, emptySearchedProductState } from '../../actions/searchedProduct';
import { emptySearchState } from '../../actions/search';

export type SearchedProductReducerState = ProductEntries;
const initialState: SearchedProductReducerState = {};


const handleSetSearchedProductState = (
  state: SearchedProductReducerState,
  action: ReturnType<typeof setSearchedProductState>
): SearchedProductReducerState => {
  const { searchedProductState } = action.payload;
  return {
    ...state,
    ...searchedProductState
  };
};

const handleEmptySearchedProductState = (
  state: SearchedProductReducerState,
  action: ReturnType<typeof emptySearchedProductState>
): SearchedProductReducerState => {
  return {};
};

const searchedProduct: Reducer<SearchedProductReducerState> = createReducer(
  initialState
)
  .handleAction(setSearchedProductState, handleSetSearchedProductState)
  .handleAction(emptySearchedProductState, handleEmptySearchedProductState)
  .handleAction(emptySearchState, handleEmptySearchedProductState);

export default searchedProduct;
