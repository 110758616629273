import { createReducer } from 'typesafe-actions';
import { Reducer } from 'redux';
import { createTicketAsyncAction } from '../../actions/ticket';

export type SessionReducerState = {
    readonly isError?: boolean;
    readonly isRequestInProgress: boolean;
};

const initialState: SessionReducerState = {
    isRequestInProgress: false
};

const session: Reducer<SessionReducerState> = createReducer(initialState)
    .handleAction(createTicketAsyncAction.request, handleCreateTicketRequest)
    .handleAction(createTicketAsyncAction.success, handleCreateTicketSuccess)
    .handleAction(createTicketAsyncAction.failure, handleCreateTicketFailure);

function handleCreateTicketRequest(
    state: SessionReducerState,
    action: ReturnType<typeof createTicketAsyncAction.request>
): SessionReducerState {
    return {
        ...state,
        isError: false,
        isRequestInProgress: true
    };
}

function handleCreateTicketSuccess(
    state: SessionReducerState,
    action: ReturnType<typeof createTicketAsyncAction.success>
): SessionReducerState {
    return {
        ...state,
        isRequestInProgress: false
    };
}

function handleCreateTicketFailure(
    state: SessionReducerState,
    action: ReturnType<typeof createTicketAsyncAction.failure>
): SessionReducerState {

    return {
        ...state,
        isError: true,
        isRequestInProgress: false
    };
}

export default session;