import * as React from "react";
import "./SearchDescription.scss";
import TextAreaField from "../TextAreaField/TextAreaField";
import ButtonUI from "../Button/Button";
import SearchBase from "../SearchBase/SearchBase";
import ProgressBar from "../ProgressBar/ProgressBar";
import ProductDetails from "../ProductDetails/ProductDetails";
import { useTranslation } from 'react-i18next';

export type SearchDescriptionDataProps = {
  descriptionValue: string;
  amountValue: number;
  image: string;
  brand: string;
  title: string;
  category: string;
  description: string;
  productNumber: string;
  url: string;
  offerNumber: string;
  marketplace: boolean;
};

export type SearchDescriptionEventProps = {
  onChangeSearchDescription: (query: string) => void;
  onSubmitDescriptionForm: () => void;
  onBackToBrandForm: () => void;
};

export type SearchDescriptionProps = SearchDescriptionDataProps & SearchDescriptionEventProps;

const SearchDescription: React.FC<SearchDescriptionProps> = props => {
  const { descriptionValue, onChangeSearchDescription, onSubmitDescriptionForm, onBackToBrandForm } = props;
  const { t } = useTranslation();

  const onSubmit = (e: React.FormEvent) => {
    // Prevent form submitting to avoid refresh on any other page except of "/?#/"
    e.preventDefault();
    onSubmitDescriptionForm();
  };

  return (
    <SearchBase headline={t('further_information', 'Haben Sie noch weitere Informationen?')}>
      <div className="search-description-ui">
        <form onSubmit={onSubmit}>
          <TextAreaField
            label={t('product_link', 'Link zum Produkt / Beschreibung / Weiteres')}
            value={descriptionValue}
            onChange={(value: string) =>
              onChangeSearchDescription(value)
            }
          />
          <ProductDetails {...props} />
          <div className="button-side">
            <ButtonUI type="button" color="grey" onClick={onBackToBrandForm}>{t('back', 'Zurück')}</ButtonUI>
            <ButtonUI type="submit" disabled={descriptionValue.length < 1}>
              {t('forward', 'Weiter')}
            </ButtonUI>
          </div>
          <ProgressBar size={66.6} />
        </form>
      </div>
    </SearchBase>
  );
};

export default SearchDescription;
