import React from 'react';
import { connect } from 'react-redux';
import { withTranslation, WithTranslation } from 'react-i18next';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import './Header.scss';
import { RootState } from 'PROCUREMENTTypes';
import LanguageLayer from '../LanguageLayer/LanguageLayer';
import {getCurrentMarketLanguages, getMarket, getMarketLink} from '../../utils/i18n';

interface IHeaderState {
  showLanguageLayer: boolean;
  showLanguageLayerLink: boolean;
}

class Header extends React.PureComponent<WithTranslation, IHeaderState> {
  constructor(props: WithTranslation) {
    super(props);
    const showLanguageLayerLink = getCurrentMarketLanguages().length > 1;

    this.state = { showLanguageLayer: false, showLanguageLayerLink };
  }

  handleLanguageLayer = () => {
    this.setState({ showLanguageLayer: !this.state.showLanguageLayer });
  }

  handleLanguageLayerClose = () => {
    if (this.state.showLanguageLayer === true) {
      this.setState({ showLanguageLayer: false });
    }
  }

  render() {
    return (
      <div className="header-ui">
        <div className="header-content">
          <div className="left-side">
            <a href={getMarketLink('home')} className="logo-link">
              <img src="icons/logo.svg" alt="Conrad" className="logo" />
            </a>
            <span className="logo-text">
              {this.props.t('business', 'Geschäftlich')}
            </span>
          </div>

          <div className="right-side">
            {this.state.showLanguageLayerLink &&
            <div className="country-language-wrapper">
              <ClickAwayListener onClickAway={this.handleLanguageLayerClose}>
                <div className="country-language-item">
                  <button className="country-language-button" onClick={this.handleLanguageLayer}>{this.props.t('select_language', 'Select Language')}</button>
                  {this.state.showLanguageLayer && <LanguageLayer closeLayerFunction={this.handleLanguageLayerClose}/>}
                </div>
              </ClickAwayListener>
            </div>
            }
            <a href={getMarketLink('account')} className="menu-link">
              <span className="menu-icon-block">
                <img src="icons/myaccount.svg" alt={this.props.t('my_account', 'Mein Konto')} className="menu-icon" />
              </span>
              <div className="menu-link-text desktop-only">
                {this.props.t('my_account', 'Mein Konto')}
              </div>
            </a>
          </div>
        </div>
        <div className="under-header-line"><div className="poligon"></div></div>
        <div className="breadcrumbs">
          {getMarket() === 'de' && <>
            <a href={getMarketLink('home')} className="breadcrumb desktop-only">
              {this.props.t('start', 'Start')}
            </a>
            <img src="icons/arrow-right.svg" alt="" className="arrow-right desktop-only" />
            <a href={getMarketLink('service')} className="breadcrumb desktop-only">
              {this.props.t('service_help', 'Service & Hilfe')}
            </a>
            <img src="icons/arrow-right.svg" alt="" className="arrow-right" />
            <a href="https://www.conrad.de/de/service/bestellung-und-beschaffung.html" className="breadcrumb">
              {this.props.t('order_procurement', 'Bestellung & Beschaffung')}
            </a>
            <img src="icons/arrow-right.svg" alt="" className="arrow-right" />
            <a
              href="https://www.conrad.de/de/service/bestellung-und-beschaffung/beschaffungsservice.html"
              className="breadcrumb"
            >
              {this.props.t('eProcurement', 'Beschaffungsservice')}
            </a>
            <img src="icons/arrow-right.svg" alt="" className="arrow-right desktop-only" />
            <span className="breadcrumb desktop-only">
              {this.props.t('online_form', 'Onlineformular')}
            </span>
            </>
          }
          </div>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): WithTranslation => {
  return {
  } as WithTranslation;
};

export default connect(mapStateToProps)(withTranslation()(Header));
