import { createReducer } from 'typesafe-actions';
import { Reducer } from 'redux';
import { getSampleSuccess } from '../../actions/sample';

export type SampleReducerState = string[];
const initialState: SampleReducerState = [];

const handleGetSampleSuccess = (
  state: SampleReducerState,
  action: ReturnType<typeof getSampleSuccess>
): SampleReducerState => {
  return [
    ...state,
    'sample'
  ];
};

const sample: Reducer<SampleReducerState> = createReducer(
  initialState
)
  .handleAction(getSampleSuccess, handleGetSampleSuccess);

export default sample;
