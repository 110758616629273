import Url from 'url-parse';
import { Maybe } from 'tsmonad';
import { getCurrentLanguageForUrl, getMarket, getShopId } from './utils/i18n';

const getConradAPIKey = (): string => {
  const market = getMarket();
  const REACT_APP_CONRAD_API_KEY = process.env[
    'REACT_APP_CONRAD_API_KEY_' + market.toUpperCase()
  ];

  return Maybe.maybe(REACT_APP_CONRAD_API_KEY).valueOr('');
};

const getConradAPIUrl = (): string => {
  const market = getMarket();
  const REACT_APP_CONRAD_API_BASE_URL = process.env[
    'REACT_APP_CONRAD_API_BASE_URL_' + market.toUpperCase()
  ];

  return Maybe.maybe(REACT_APP_CONRAD_API_BASE_URL).valueOr('/');
};

const completeURL = (apiUrl: string, path: string): string => {
  const finalUrl = apiUrl.endsWith('/') ? apiUrl : apiUrl + '/';
  const url = new Url(path, finalUrl);
  return url.toString();
};

const completeConradURL = (path: string): string => {
  const apiUrl = getConradAPIUrl();
  return completeURL(apiUrl, path);
};

export const createConradProductEanUrl = (ean: string): string => {
  const apiKey = getConradAPIKey();
  const shopId =  getShopId();

  return completeConradURL(`/product/1/service/${shopId}/product?apikey=${encodeURIComponent(apiKey)}&q=ean:${encodeURIComponent(ean)}&language=${getCurrentLanguageForUrl()}`);
};

export const createConradSearchEanUrl = (): string => {
  const apiKey = getConradAPIKey();
  const market = getMarket().toUpperCase();
  const language = getCurrentLanguageForUrl();
  return completeConradURL(`/search/1/v3/facetSearch/${market}/${language}/b2b?apikey=${encodeURIComponent(apiKey)}&optimizegaexp=GAX1.2.-bcTBfKPTieslbmtHwNhvQ.18615.0`);
};

export const createConradMarketplaceProductUrl = (customerNumber: string): string => {
  const apiKey = getConradAPIKey();
  const shopId = getShopId();

  return completeConradURL(`/price-availability/4/${shopId}/facade?userId=${encodeURIComponent(customerNumber)}&apikey=${encodeURIComponent(apiKey)}`);
};

export const createConradSessionUrl = (): string => {
  const apiKey = getConradAPIKey();
  const redirectUrl = window.location.href.split('#')[0];
  return completeConradURL(`/session/1/userinfo?apikey=${encodeURIComponent(apiKey)}&redirect=${encodeURIComponent(redirectUrl)}`);
};

export const createApiGeniusSearchKeywordCategoryBrandUrl = (keyword: string, category: string, brand: string): string => completeConradURL(`/search?keyword=${encodeURIComponent(keyword)}&category=${encodeURIComponent(category)}&brand=${encodeURIComponent(brand)}`);

export const createApiGeniusSearchEanUrl = (ean: string): string => {
  const apiKey = getConradAPIKey();
  return completeConradURL(`/nic-lookup/1/products/lookup?apikey=${encodeURIComponent(apiKey)}&upc=${encodeURIComponent(ean)}`);
};

export const createEanSearchEanUrl = (ean: string): string => {
  const apiKey = getConradAPIKey();
  return completeConradURL(`/nic-search/1/?op=barcode-lookup&format=json&ean=${encodeURIComponent(ean)}&language=3&apikey=${encodeURIComponent(apiKey)}`);
};

export const createConradUserAddressUrl = (uid: string): string => {
  const apiKey = getConradAPIKey();
  const shopId = getShopId();

  return completeConradURL(`/user/12/${shopId}/${encodeURIComponent(uid)}?apikey=${encodeURIComponent(apiKey)}`);
};

export const getCreateTicketUrl = (): string => {
  const apiKey = getConradAPIKey();
  return completeConradURL(`/support/2/tickets.json?apikey=${encodeURIComponent(apiKey)}`);
};
