import { createAction } from 'typesafe-actions';

export type ChosenProduct = {
    key: number; // Unique internal id for react rendering
    brand: string;
    title: string;
    quantity: number;
    maxUnitPrice: number;
    description?: string;
    ean?: string;
    image?: string;
    express?: boolean;
};

export const removeAllProducts = createAction('@bs/REMOVE_ALL_PRODUCTS [DOC]')<void>();

export const removeProduct = createAction('@bs/REMOVE_PRODUCT [CMD]')<number>();

export const removeProductFromList = createAction('@bs/REMOVE_PRODUCT [DOC]')<number>();

export const addProduct = createAction('@bs/ADD_PRODUCT [DOC]')<ChosenProduct>();

export const setExpressToProduct = createAction('@bs/SET_EXPRESS_TO_PRODUCT [DOC]', (key: number, express: boolean) => ({key, express}))();
