import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Action } from 'typesafe-actions';

import './SearchAmount.scss';
import InputField from '../InputField/InputField';
import ButtonUI from '../Button/Button';
import SearchBase from '../SearchBase/SearchBase';
import ProgressBar from '../ProgressBar/ProgressBar';
import ProductDetails from '../ProductDetails/ProductDetails';
import { Actions, REJECT, CONFIRM } from '../../data/Search';

export type SearchAmountDataProps = {
  amountValue: number;
  image: string;
  brand: string;
  title: string;
  category: string;
  description: string;
  productNumber: string;
  url: string;
  offerNumber: string;
  marketplace: boolean;
  actions: Actions;
};

export type SearchAmountEventProps = {
  onChangeSearchAmount: (query: number) => void;
  onSubmitAmountForm: () => void;
  onConfirm: (action: Action) => any;
  onReject: (action: Action) => any;
};

export type SearchAmountProps = SearchAmountDataProps & SearchAmountEventProps;

const SearchAmount: React.FC<SearchAmountProps> = props => {
  const { amountValue, onChangeSearchAmount, actions, onConfirm, onReject } = props;
  const { t } = useTranslation();

  return (
    <SearchBase headline={t('amount_pieces', 'Wie viele Stück benötigen Sie?')}>
      <div className="search-amount-ui">
        <InputField
          label={t('quantity', 'Stückzahl')}
          type="number"
          autoFocus
          value={amountValue}
          onChange={(value: number) =>
            onChangeSearchAmount(value)
          }
        />
        <ProductDetails {...props} />
        <div className="button-side">
          <ButtonUI type="button" color="grey" onClick={() => onReject(actions[REJECT])}>
            {t('back', 'Zurück')}
          </ButtonUI>
          <ButtonUI type="button" disabled={amountValue < 1} onClick={() => onConfirm(actions[CONFIRM])}>
            {t('forward', 'Weiter')}
          </ButtonUI>
        </div>
        <ProgressBar size={80} />
      </div>
    </SearchBase>
  );
};

export default SearchAmount;
