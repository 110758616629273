import { createAction } from 'typesafe-actions';

export enum ProcurementStage {
    SEARCH = 'SEARCH',
    PRDUCTS_LIST = 'PRDUCTS_LIST',
    USER_DATA_FORM = 'USER_DATA_FORM',
    TICKET_CREATED = 'TICKET_CREATED',
}

export const setStage = createAction('@bs/SET_PROCUREMENT_STAGE [DOC]')<ProcurementStage>();
