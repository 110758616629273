import { createAsyncAction, createAction, Action } from 'typesafe-actions';
import { SearchEntries, Button } from '@cp/data/Search';

export const enableSearchingFlag = createAction('@bs/ENABLE_SEARCHING_FLAG [DOC]')();

export const disableSearchingFlag = createAction('@bs/DISABLE_SEARCHING_FLAG [DOC]')();

export const openSearch = createAction('@bs/OPEN_SEARCH [CMD]')();

export const addToCart = createAction('@bs/ADD_TO_CART [CMD]', (productNumber: string, offerNumber?: string) => ({productNumber, offerNumber}))();

export const getProductData = createAction('@bs/GET_PRODUCT_DATA [CMD]', (query: string) => ({query}))();

export const setSearchState = createAction('@bs/SET_SEARCH_STATE [DOC]', (searchState: SearchEntries) => ({searchState}))();

export const openSearchEanOrCategoryForm = createAction('@bs/OPEN_SEARCH_EAN_OR_CATEGORY_FORM [CMD]')();

export const openSearchBrandForm = createAction('@bs/OPEN_SEARCH_BRAND_FORM [CMD]')();

export const openSearchDescriptionForm = createAction('@bs/OPEN_DESCRIPTION_FORM [CMD]')();

export const openSearchAmountForm = createAction('@bs/OPEN_SEARCH_AMOUNT_FORM [CMD]',
    (
        confirmAction: Action,
        rejectFunction: Function
    ) => ({
        confirmAction,
        rejectFunction
    })
)();

export const openSearchResultForm = createAction('@bs/OPEN_SEARCH_RESULT_FORM [CMD]',
    (
        confirmButton: Button,
        rejectButton: Button,
        confirmAction: Action,
        rejectAction: Action,
        message: string,
        headline: string,
        success?: boolean
    ) => ({
        confirmButton,
        rejectButton,
        confirmAction,
        rejectAction,
        message,
        headline,
        success
    })
)();

export const openSearchFailureResultForm = createAction('@bs/OPEN_SEARCH_FAILURE_RESULT_FORM [CMD]',
    (
        confirmButton: Button,
        rejectButton: Button,
        confirmAction: Action,
        rejectAction: Action,
        message: string,
        headline: string,
    ) => ({
        confirmButton,
        rejectButton,
        confirmAction,
        rejectAction,
        message,
        headline
    })
)();

export const submitEanOrCategoryForm = createAction('@bs/SUBMIT_EAN_OR_CATEGORY_FORM [CMD]')();

export const submitBrandForm = createAction('@bs/SUBMIT_BRAND_FORM [CMD]')();

export const submitDescriptionForm = createAction('@bs/SUBMIT_DESCRIPTION_FORM [CMD]')();

export const submitAmountForm = createAction('@bs/SUBMIT_AMOUNT_FORM [CMD]')();

export const setSearchEanOrCategoryValue = createAction('@bs/SET_SEARCH_EAN_OR_CATEGORY_VALUE [DOC]', (value: string) => ({value}))();

export const setSearchBrandValue = createAction('@bs/CHANGE_SEARCH_BRAND_VALUE [DOC]', (value: string) => ({value}))();

export const setSearchDescriptionValue = createAction('@bs/SET_SEARCH_DESCRIPTION_VALUE [DOC]', (value: string) => ({value}))();

export const setSearchAmountValue = createAction('@bs/SET_SEARCH_AMOUNT_VALUE [DOC]', (value: number) => ({value}))();

export const setSearchProduct = createAction('@bs/SET_SEARCH_PRODUCT [CMD]', (image: string, title: string, brand: string, category: string, productNumber?: string, url?: string, offerNumber?: string, marketplace?: boolean) => ({image, title, brand, category, productNumber, url, offerNumber, marketplace}))();

export const closeSearch = createAction('@bs/CLOSE_SEARCH [CMD]')();

export const emptySearchState = createAction('@bs/EMPTY_SEARCH_STATE [DOC]')();

export const getProductDataConradProductAsyncAction = createAsyncAction(
    '@bs/GET_PRODUCT_DATA_CONRAD_PRODUCT_REQUEST [CMD]',
    '@bs/GET_PRODUCT_DATA_CONRAD_PRODUCT_SUCCESS [EVENT]',
    '@bs/GET_PRODUCT_DATA_CONRAD_PRODUCT_FAILURE [EVENT]'
)<string, [{title: string, brand: string, image: string, productNumber: string, marketplace?: boolean}, string], [Error, string]>();

export const getProductDataEanConradSearchAsyncAction = createAsyncAction(
    '@bs/GET_PRODUCT_DATA_EAN_CONRAD_SEARCH_REQUEST [CMD]',
    '@bs/GET_PRODUCT_DATA_EAN_CONRAD_SEARCH_SUCCESS [EVENT]',
    '@bs/GET_PRODUCT_DATA_EAN_CONRAD_SEARCH_FAILURE [EVENT]'
)<string, [[{productShortInformation: any, brand: {displayName: string}, shortProductNumber: string}], string], [Error, string]>();

export const getProductDataEanApiGeniusAsyncAction = createAsyncAction(
    '@bs/GET_PRODUCT_DATA_EAN_API_GENIUS_REQUEST [CMD]',
    '@bs/GET_PRODUCT_DATA_EAN_API_GENIUS_SUCCESS [EVENT]',
    '@bs/GET_PRODUCT_DATA_EAN_API_GENIUS_FAILURE [EVENT]'
)<string, [any, string], [Error, string]>();

export const getProductDataEanEanSearchAsyncAction = createAsyncAction(
    '@bs/GET_PRODUCT_DATA_EAN_EAN_SEARCH_REQUEST [CMD]',
    '@bs/GET_PRODUCT_DATA_EAN_EAN_SEARCH_SUCCESS [EVENT]',
    '@bs/GET_PRODUCT_DATA_EAN_EAN_SEARCH_FAILURE [EVENT]'
)<string, [any, string], [Error, string]>();

export const getProductDataKeywordCategoryBrandApiGeniusAsyncAction = createAsyncAction(
    '@bs/GET_PRODUCT_DATA_KEYWORD_CATEGORY_BRAND_API_GENIUS_REQUEST [CMD]',
    '@bs/GET_PRODUCT_DATA_KEYWORD_CATEGORY_BRAND_API_GENIUS_SUCCESS [EVENT]',
    '@bs/GET_PRODUCT_DATA_KEYWORD_CATEGORY_BRAND_API_GENIUS_FAILURE [EVENT]'
)<{keyword: string, category: string, brand: string}, any, Error>();

export const getProductDataNameEanSearchAsyncAction = createAsyncAction(
    '@bs/GET_PRODUCT_DATA_NAME_EAN_SEARCH_REQUEST [CMD]',
    '@bs/GET_PRODUCT_DATA_NAME_EAN_SEARCH_SUCCESS [EVENT]',
    '@bs/GET_PRODUCT_DATA_NAME_EAN_SEARCH_FAILURE [EVENT]'
)<string, any, Error>();

export const getProductDataConradMarketplaceAsyncAction = createAsyncAction(
    '@bs/GET_PRODUCT_DATA_CONRAD_MARKETPLACE_REQUEST [CMD]',
    '@bs/GET_PRODUCT_DATA_CONRAD_MARKETPLACE_SUCCESS [EVENT]',
    '@bs/GET_PRODUCT_DATA_CONRAD_MARKETPLACE_FAILURE [EVENT]'
)<[string, string], [any, string], [Error, string]>();
