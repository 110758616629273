import { takeEvery, put, select } from 'redux-saga/effects';
import { RootState } from "PROCUREMENTTypes";
import { removeProduct, removeProductFromList } from '../../actions/productsList';
import { getProductsList } from '../../../selectors/productsList';
import { openSearch } from '../../actions/search';
import { ProcurementStage, setStage } from '../../actions/stage';

export function* watchRemoveProduct() {
    yield takeEvery(removeProduct, handleRemoveProduct);
}

function* handleRemoveProduct(action: ReturnType<typeof removeProduct>): Generator {
    const number = action.payload;
    const state = yield select();
    const productsList = getProductsList(state as RootState);
    if (productsList.length < 2) {
        yield put(removeProductFromList(number));
        yield put(openSearch());
        yield put(setStage(ProcurementStage.SEARCH));
    } else {
        yield put(removeProductFromList(number));
    }
  }