import * as React from "react";

import './LanguageListItem.scss';

export interface LanguageListItemProps {
  id: string;
  title: string;
  changeFunction: Function;
  isSelected?: boolean;
}

export const LanguageListItem: React.FC<LanguageListItemProps> = ({ id, title, isSelected=false, changeFunction }) => {

  return (
    <li className={`language-list-item ${(isSelected) ? 'language-list-item--selected' : ''}`}>
      <button className="language-list-item__button" type="button" onClick={() => changeFunction(id)}>
        <span className="language-list-item__text">{title}</span>
      </button>
    </li>
  );
}
