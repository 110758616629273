import {all, fork} from 'redux-saga/effects';

import { watchSample } from '../redux/saga/sample';
import {
  watchGetProductDataEanConradSearchRequest,
  // watchGetProductDataEanConradSearchSuccess,
  // watchGetProductDataEanConradSearchFailure,
  watchGetProductDataEanApiGenius,
  watchGetProductDataEanEanSearch,
  watchSubmitEanOrCategoryForm,
  watchSubmitBrandForm,
  watchSubmitDescriptionForm,
  watchSubmitAmountForm,
  watchSetSearchProduct,
  watchOpenSearchResultForm,
  watchGetProductDataEanSuccessSuccess,
  watchGetProductDataEanSuccessFailure,
  watchGetProductDataEanFailureSuccess,
  watchGetProductDataEanFailureFailure,
  watchOpenSearch,
  watchAddToCart,
  watchCloseSearch,
  watchGetProductDataConradMarketplaceRequest,
  // watchGetProductDataConradMarketplaceSuccess,
  // watchGetProductDataConradMarketplaceFailure,
  watchGetProductDataConradProductRequest,
  // watchGetProductDataConradProductSuccess,
  // watchGetProductConradSuccessSuccessSuccess,
  // watchGetProductConradSuccessFailure,
  // watchGetProductConradFailureSuccessSuccess,
  // watchGetProductConradFailureFailureFailure,
  watchOpenSearchFailureResultForm,
  watchOpenSearchAmountForm
} from '../redux/saga/search';
import {
  watchGetProductConradSuccessSuccess,
  watchGetProductConradSuccessFailure,
  watchGetProductConradFailureSuccess,
  watchGetProductConradFailureFailure,
  watchGetProductDataConradMarketplaceSuccess,
  watchGetProductDataConradMarketplaceFailure,
} from '../redux/saga/searchConrad';
import { watchGetSession } from '../redux/saga/session';
import { watchGetAddress } from '../redux/saga/user';
import { watchCreateTicket } from '../redux/saga/ticket';
import { watchRemoveProduct } from '../redux/saga/productsList';

export default function* rootSaga() {
  yield all([
    fork(watchSample),
    fork(watchGetSession),
    fork(watchSubmitEanOrCategoryForm),
    fork(watchGetAddress),
    fork(watchCreateTicket),
    fork(watchGetProductDataEanSuccessSuccess),
    fork(watchGetProductDataEanSuccessFailure),
    fork(watchGetProductDataEanFailureSuccess),
    fork(watchGetProductDataEanFailureFailure),
    fork(watchOpenSearch),
    fork(watchAddToCart),
    fork(watchCloseSearch),
    fork(watchSubmitBrandForm),
    fork(watchSubmitDescriptionForm),
    fork(watchSubmitAmountForm),
    fork(watchSetSearchProduct),
    fork(watchOpenSearchResultForm),
    fork(watchGetProductDataEanConradSearchRequest),
    // fork(watchGetProductDataEanConradSearchSuccess),
    // fork(watchGetProductDataEanConradSearchFailure),
    fork(watchGetProductDataEanApiGenius),
    fork(watchGetProductDataEanEanSearch),
    fork(watchGetProductDataConradMarketplaceRequest),
    // fork(watchGetProductDataConradMarketplaceSuccess),
    // fork(watchGetProductDataConradMarketplaceFailure),
    fork(watchGetProductDataConradProductRequest),
    // fork(watchGetProductDataConradProductSuccess),
    // fork(watchGetProductConradSuccessSuccessSuccess),
    // fork(watchGetProductConradSuccessFailure),
    // fork(watchGetProductConradFailureSuccessSuccess),
    // fork(watchGetProductConradFailureFailureFailure),
    fork(watchOpenSearchFailureResultForm),
    fork(watchGetProductConradSuccessSuccess),
    fork(watchGetProductConradSuccessFailure),
    fork(watchGetProductConradFailureSuccess),
    fork(watchGetProductConradFailureFailure),
    fork(watchGetProductDataConradMarketplaceSuccess),
    fork(watchGetProductDataConradMarketplaceFailure),
    fork(watchOpenSearchAmountForm),
    fork(watchRemoveProduct)
  ]);
}
