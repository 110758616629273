import {applyMiddleware, createStore as createReduxStore} from 'redux';
import createSagaMiddleware from 'redux-saga';
import {composeWithDevTools} from 'redux-devtools-extension';

import rootReducer from './root-reducer';
import rootSaga from './root-saga';
import * as urls from '../urls';

export const sagaMiddleware = createSagaMiddleware({
  context: {
    ...urls
  }
});

const createStore = (): ReturnType<typeof createReduxStore> => {
  const composeEnhancers = composeWithDevTools({});
  const middleware = [sagaMiddleware];
  const store = createReduxStore(
    rootReducer(),
    {},
    composeEnhancers(applyMiddleware(...middleware))
  );

  sagaMiddleware.run(rootSaga);

  return store;
};

export default createStore;
