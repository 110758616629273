import * as React from "react";
import ReactHtmlParser from 'react-html-parser';
import "./FailureResultForm.scss";

import ButtonUI from "../Button/Button";
import SearchBase from "../SearchBase/SearchBase";
import ProgressBar from "../ProgressBar/ProgressBar";
import { SearchButtons, REJECT, Actions } from "../../data/Search";

import { Action } from "typesafe-actions";

export type FailureResultFormDataProps = {
  headline: string;
  buttons: SearchButtons;
  animation: number;
  message: string;
  actions: Actions;
};

export type FailureResultFormEventProps = {
  onConfirm: (action: Action) => any;
  onReject: (action: Action) => any;
};

export type FailureResultFormProps = FailureResultFormDataProps & FailureResultFormEventProps;

const FailureResultForm: React.FC<FailureResultFormProps> = props => {
  const { headline, buttons, message, actions, onConfirm } = props;
  return (
    <SearchBase headline={headline}>
      <div className="failure-result-form-ui">
        <div className="failure" />
        <div className="message">{ReactHtmlParser(message)}</div>
        <ProgressBar size={100} />
        <div className="button-side">
          <ButtonUI type="button" color={buttons[REJECT].color as 'yellow'|'grey'} onClick={() => onConfirm(actions[REJECT])}>{buttons[REJECT].text}</ButtonUI>
        </div>
      </div>
    </SearchBase>
  );
};

export default FailureResultForm;
