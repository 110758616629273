import React from 'react';
import { connect } from 'react-redux';
import './Layout.scss';
import { RootState } from 'PROCUREMENTTypes';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';

interface ILayoutProps {
    children?: any;
}

interface ILayoutState {
}

class Layout extends React.PureComponent<ILayoutProps, ILayoutState> {

	render() {

		return (
            <div className="layout-ui">
                <Header/>
                {this.props.children}
                <Footer/>
            </div>
		);
	}
}

const mapStateToProps = (state: RootState): ILayoutProps => {
	return {
	} as ILayoutProps;
};

export default connect(mapStateToProps)(Layout);
