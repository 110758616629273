import * as React from "react";
import "./SearchEanOrCategory.scss";
import InputField from "../InputField/InputField";
import ButtonUI from "../Button/Button";
import SearchBase from "../SearchBase/SearchBase";
import ProgressBar from "../ProgressBar/ProgressBar";
import { useTranslation } from 'react-i18next';

export type SearchEanOrCategoryDataProps = {
  searching: boolean;
  eanOrCategoryValue: string;
};

export type SearchEanOrCategoryEventProps = {
  onChangeSearchEanOrCategory: (value: string) => void;
  onSubmitEanOrCategoryForm: () => void;
};

export type SearchEanOrCategoryProps = SearchEanOrCategoryDataProps & SearchEanOrCategoryEventProps;

const SearchEanOrCategory: React.FC<SearchEanOrCategoryProps> = props => {
  const { eanOrCategoryValue, onChangeSearchEanOrCategory, onSubmitEanOrCategoryForm, searching } = props;
  const isNumber = !isNaN(eanOrCategoryValue as any);
  const valueLength = eanOrCategoryValue.length;
  const disabled = isNumber ? valueLength < 7 : valueLength < 1;
  const error = isNumber ? valueLength > 15 : false;
  const { t } = useTranslation();

  const onSubmit = (e: React.FormEvent) => {
    // Prevent form submitting to avoid refresh on any other page except of "/?#/"
    e.preventDefault();
    onSubmitEanOrCategoryForm();
  };
  const label = `${t('EAN_number_start', 'EAN_Nummer')} <span class="ean-icon"></span> ${t('EAN_number_end', 'oder Produktbezeichnung')}`;

  return (
    <SearchBase headline={t('product_description', 'Um welches Produkt handelt es sich?')}>
      <div className="search-ean-category-ui">
        <form onSubmit={onSubmit}>
          <InputField
            label={label}
            value={eanOrCategoryValue}
            autoFocus
            onChange={(value: string) =>
              onChangeSearchEanOrCategory(value)
            }
            error={error}
            errorMessage={t('error_unknown_number', 'Diese Nummer ist uns leider nicht bekannt. Versuchen Sie es noch einmal.')}
          />
          <div className="button-side">
            <ButtonUI type="submit" disabled={disabled || error || searching}>
              {t('forward', 'Weiter')}
            </ButtonUI>
          </div>
          <ProgressBar size={16.6} />
      </form>
      </div>
    </SearchBase>
  );
};

export default SearchEanOrCategory;
