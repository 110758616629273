import { Action } from 'redux';

export const EANORCATEGORY = 'eanOrCategory';
export const BRAND = 'brand';
export const DESCRIPTION = 'description';
export const AMOUNT = 'amount';
export const RESULT = 'result';
export const FAILURE = 'failure';

export const CONFIRM = 'confirm';
export const REJECT = 'reject';

export type SearchTypes = typeof EANORCATEGORY | typeof BRAND | typeof DESCRIPTION | typeof AMOUNT | typeof RESULT | typeof FAILURE;

export type Button = {
    text: string;
    color?: string;
    icon?: boolean;
    isAddToCart?: boolean;
};

export type Actions = {
    [CONFIRM]: Action;
    [REJECT]: Action;
};

export type SearchButtons = {
    [CONFIRM]: Button;
    [REJECT]: Button;
};

export type Product = {
    image?: string;
    brand?: string;
    title?: string;
    category?: string;
    description?: string;
    productNumber?: string;
    url?: string;
    offerNumber?: string;
    marketplace?: boolean;
};

export type SearchEntries = {
    searching?: boolean;
    searchType?: SearchTypes;
    eanOrCategoryValue?: string;
    brandValue?: string;
    descriptionValue?: string;
    amountValue?: number;
    buttons?: SearchButtons;
    actions?: Actions;
    animation?: number;
    product?: Product;
    message?: string;
    headline?: string;
    label?: string;
    success?: boolean;
    back?: Action;
};
