import * as React from 'react';
import { Maybe } from 'tsmonad';
import when from 'when-switch';
import { SearchTypes, EANORCATEGORY, BRAND, DESCRIPTION, AMOUNT, RESULT, SearchButtons, Actions, FAILURE } from '../../data/Search';
import SearchEanOrCategory from '../SearchEanOrCategory/SearchEanOrCategory';
import SearchBrand from '../SearchBrand/SearchBrand';
import SearchDescription from '../SearchDescription/SearchDescription';
import SearchAmount from '../SearchAmount/SearchAmount';
import ResultForm from '../ResultForm/ResultForm';
import { Action } from 'typesafe-actions';
import './Search.scss';
import FailureResultForm from '../FailureResultForm/FailureResultForm';

export type SearchDataProps = {
  searching: boolean;
  searchType: Maybe<SearchTypes>;
  eanOrCategoryValue: string;
  brandValue: string;
  descriptionValue: string;
  amountValue: number;
  buttons: SearchButtons;
  animation: number;
  image: string;
  brand: string;
  title: string;
  category: string;
  description: string;
  productNumber: string;
  url: string;
  offerNumber: string;
  marketplace: boolean;
  message: string;
  headline: string;
  label: string;
  actions: Actions;
  success: boolean;
};

export type SearchEventProps = {
  onChangeSearchEanOrCategory: (value: string) => any;
  onSubmitEanOrCategoryForm: () => any;
  onChangeSearchBrand: (value: string) => any;
  onSubmitBrandForm: () => any;
  onChangeSearchDescription: (value: string) => any;
  onSubmitDescriptionForm: () => any;
  onChangeSearchAmount: (value: number) => any;
  onSubmitAmountForm: () => any;
  onBackToBrandForm: () => any;
  onBackToAmountForm: () => any;
  onConfirm: (action: Action) => any;
  onReject: (action: Action) => any;
};

export type SearchProps = SearchDataProps & SearchEventProps;

const Search: React.FC<SearchProps> = props => {
  const { searchType } = props;
  return (
    <div className="search-ui">
        {searchType.caseOf({
            just: type =>
            when(type)
                .is(EANORCATEGORY, () => <SearchEanOrCategory {...props} />)
                .is(BRAND, () => <SearchBrand {...props} />)
                .is(DESCRIPTION, () => <SearchDescription {...props} />)
                .is(AMOUNT, () => <SearchAmount {...props} />)
                .is(RESULT, () => <ResultForm {...props} />)
                .is(FAILURE, () => <FailureResultForm {...props} />)
                .else(null),
            nothing: () => null
        })}
    </div>
    );
};

export default Search;
