import { RootState } from "PROCUREMENTTypes";
import { Maybe } from "tsmonad";
import { ProductEntries } from "../../data/Product";

export const getSearchedProductEntries = (state: RootState): ProductEntries =>
  state.searchedProduct;

export const getSearchedProductImage = (state: RootState): Maybe<string> => Maybe.maybe(getSearchedProductEntries(state).image);

export const getSearchedProductBrand = (state: RootState): Maybe<string> => Maybe.maybe(getSearchedProductEntries(state).brand);

export const getSearchedProductTitle = (state: RootState): Maybe<string> => Maybe.maybe(getSearchedProductEntries(state).title);

export const getSearchedProductCategory = (state: RootState): Maybe<string> => Maybe.maybe(getSearchedProductEntries(state).category);

export const getSearchedProductDescription = (state: RootState): Maybe<string> => Maybe.maybe(getSearchedProductEntries(state).description);

export const getSearchedProductProductNumber = (state: RootState): Maybe<string> => Maybe.maybe(getSearchedProductEntries(state).productNumber);

export const getSearchedProductUrl = (state: RootState): Maybe<string> => Maybe.maybe(getSearchedProductEntries(state).url);

export const getSearchedProductOfferNumber = (state: RootState): Maybe<string> => Maybe.maybe(getSearchedProductEntries(state).offerNumber);

export const getSearchedProductMarketplace = (state: RootState): Maybe<boolean> => Maybe.maybe(getSearchedProductEntries(state).marketplace);
