import * as React from 'react';
import ReactHtmlParser from 'react-html-parser';
import './ResultForm.scss';
import styles from './Icon.module.scss';
import ButtonUI from '../Button/Button';
import SearchBase from '../SearchBase/SearchBase';
import ProgressBar from '../ProgressBar/ProgressBar';
import { SearchButtons, REJECT, CONFIRM, Actions } from '../../data/Search';
import ProductDetails from '../ProductDetails/ProductDetails';
import { Action } from 'typesafe-actions';
import { isDACHMarket } from '../../utils/i18n';

export type ResultFormDataProps = {
  headline: string;
  buttons: SearchButtons;
  animation: number;
  message: string;
  actions: Actions;
  success: boolean;
  amountValue: number;
  image: string;
  brand: string;
  title: string;
  category: string;
  description: string;
  productNumber: string;
  url: string;
  offerNumber: string;
  marketplace: boolean;
};

export type ResultFormEventProps = {
  onConfirm: (action: Action) => any;
  onReject: (action: Action) => any;
};

export type ResultFormProps = ResultFormDataProps & ResultFormEventProps;

const ResultForm: React.FC<ResultFormProps> = props => {
  const { headline, buttons, message, actions, onConfirm, onReject, success } = props;
  return (
    <SearchBase headline={headline}>
      <div className="result-form-ui">
        {success ? <div className="success" /> : null}
        <div className="message">{ReactHtmlParser(message)}</div>
        <ProductDetails {...props} />
        <ProgressBar size={100} />
        <div className="button-side">
          <ButtonUI type="button" color={buttons[REJECT].color as 'yellow'|'grey'} onClick={() => onConfirm(actions[REJECT])}>{buttons[REJECT].text}</ButtonUI>
          {((buttons[CONFIRM].isAddToCart && isDACHMarket()) || !buttons[CONFIRM].isAddToCart) && <ButtonUI type="button" color={buttons[CONFIRM].color as 'yellow'|'grey'} onClick={() => onReject(actions[CONFIRM])}>
            {buttons[CONFIRM].icon ? <span className={styles.icon}></span> : null}
            {buttons[CONFIRM].text}
          </ButtonUI>}
        </div>
      </div>
    </SearchBase>
  );
};

export default ResultForm;
