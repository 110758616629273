import React from 'react';
import { connect } from 'react-redux';
import './Procurement.scss';
import { RootState } from 'PROCUREMENTTypes';
import { RouteComponentProps } from 'react-router-dom';
import ProductsList from '../ProductsList/ProductsList';
import UserDataForm from '../UserDataForm/UserDataForm';
import Search from '../Search';
import SuccessMessage from '../SuccessMessage/SuccessMessage';
import Layout from '../Layout/Layout';

interface IProcurementProps extends RouteComponentProps {
}

interface IProcurementState {
}

class Procurement extends React.Component<IProcurementProps, IProcurementState> {

	render() {
		return (
            <Layout>
                <div className="procurement-ui">
                    <Search/>
                    <ProductsList/>
                    <UserDataForm/>
                    <SuccessMessage/>
                </div>
            </Layout>
		);
	}
}

const mapStateToProps = (state: RootState): IProcurementProps => {
	return {
	} as IProcurementProps;
};

export default connect(mapStateToProps)(Procurement);
