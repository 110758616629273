import { createReducer } from 'typesafe-actions';
import { Reducer } from 'redux';
import { getAddressAsyncAction } from '../../actions/user';

export type UserReducerState = {
    readonly academicTitle?: string;
    readonly salutation?: string;
    readonly firstName?: string;
    readonly lastName?: string;
    readonly city?: string;
    readonly houseNumber?: string;
    readonly country?: string;
    readonly street?: string;
    readonly zip?: string;
    readonly phone?: string;
    readonly isSuccessfullyFetched?: boolean;
    readonly sapCustomerNumber?: string;
};

type SubUserData = {
    academicTitle?: string;
    firstName?: string;
    lastName?: string;
    communicationEmailAddress?: string;
    primaryPhoneNumber?: string;
    salutationCode?: string;
    sapContactNumber?: string;
};

type UserAddress = {
    city?: string;
    country?: string;
    houseNumber?: string;
    street?: string;
    zip?: string;
};

type BusinessMainUserData = {
    sapCustomerNumber?: string;
    billingAddress?: UserAddress;
};

const initialState: UserReducerState = {};

const session: Reducer<UserReducerState> = createReducer(initialState)
    .handleAction(getAddressAsyncAction.success, handleGetAddressSuccess)
    .handleAction(getAddressAsyncAction.failure, handleGetAddressFailure);

function handleGetAddressSuccess(
    state: UserReducerState,
    action: ReturnType<typeof getAddressAsyncAction.success>
): UserReducerState {
    const subUser: SubUserData = action.payload.userInformation?.businessSubUserData || {};
    const businessMainUserData: BusinessMainUserData = action.payload.userInformation?.businessMainUserData || {};
    const address: UserAddress = businessMainUserData.billingAddress || {};
    return {
        ...state,
        isSuccessfullyFetched: true,
        academicTitle: subUser.academicTitle || '',
        salutation: subUser.salutationCode || '',
        firstName: subUser.firstName || '',
        lastName: subUser.lastName || '',
        phone: subUser.primaryPhoneNumber || '',
        street: address.street || '',
        houseNumber: address.houseNumber || '',
        zip: address.zip || '',
        city: address.city || '',
        country: address.country || '',
        sapCustomerNumber: businessMainUserData.sapCustomerNumber || ''
    };
}

function handleGetAddressFailure(
    state: UserReducerState,
    action: ReturnType<typeof getAddressAsyncAction.failure>
): UserReducerState {
    console.error('User address request failure');
    return {
        ...state,
        isSuccessfullyFetched: false
    };
}

export default session;