import * as React from 'react';
import { connect, useDispatch } from 'react-redux';
import { LanguageListItem } from './../LanguageListItem/LanguageListItem';
import './LanguageLayer.scss';
import { setLanguage } from '../../redux/actions/language';
import {RootState} from 'PROCUREMENTTypes';
import i18n from '../../i18n';

const languages = [
  { id: 'de', title: 'Deutsch' },
  { id: 'de-CH', title: 'Deutsch' },
  { id: 'en', title: 'English' },
  { id: 'fr', title: 'Français' },
  { id: 'fr-CH', title: 'Français' },
  { id: 'cz', title: 'Čeština' },
  { id: 'dk', title: 'Dansk' },
  { id: 'hr', title: 'Hrvatski' },
  { id: 'hu', title: 'Magyar' },
  { id: 'it', title: 'Italiano' },
  { id: 'nl', title: 'Nederlands'},
  { id: 'pl', title: 'Polskie' },
  { id: 'se', title: 'Svenska' },
  { id: 'sl', title: 'Slovenščina' },
  { id: 'sk', title: 'Slovák' },
];

interface LanguageLayerProps {
  closeLayerFunction: Function;
  marketLanguages: string[];
}

const LanguageLayer: React.FC<LanguageLayerProps> = ({closeLayerFunction, marketLanguages}) => {
  const dispatch = useDispatch();

  const availableLanguages = languages.filter(lang => marketLanguages.indexOf(lang.id) > -1);

  const handleLanguageSelect = (languageId: string) => {
    dispatch(setLanguage(languageId));
    i18n.changeLanguage(languageId);
    document.title = i18n.t('eProcurement', 'Beschaffungsservice');
    closeLayerFunction();
  };

  return (
    <div className="language-layer" >
      <ul className="language-list">
        {availableLanguages.map((lang) => <LanguageListItem
          id={lang.id}
          title={lang.title}
          key={lang.id}
          isSelected={(i18n.language === lang.id)}
          changeFunction={handleLanguageSelect}
          />
        )}
      </ul>
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    marketLanguages: state.market.languages
  };
};

export default connect(mapStateToProps)(LanguageLayer);
