import * as React from "react";
import { TextareaAutosize } from "@material-ui/core";
import "./TextAreaField.scss";

export type TextAreaFieldDataProps = {
    label?: string;
    value: any;
};

export type TextAreaFieldEventProps = {
    onChange: (value: any) => void;
};

export type TextAreaFieldProps = TextAreaFieldDataProps & TextAreaFieldEventProps;

const TextAreaField: React.FC<TextAreaFieldProps> = props => {
    const {label, value, onChange} = props;

    return (
        <div className="textarea-ui">
            <label className="label">{label}</label>
            <TextareaAutosize className="textarea" value={value} onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => onChange(event.target.value)} />
        </div>
    );
};

export default TextAreaField;
