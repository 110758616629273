import { createReducer } from 'typesafe-actions';
import { Reducer } from 'redux';
import { ProcurementStage, setStage } from '../../actions/stage';

const initialState: ProcurementStage = ProcurementStage.SEARCH;

const stage: Reducer<ProcurementStage> = createReducer(initialState)
    .handleAction(setStage, handleSetStage);

function handleSetStage(
    state: ProcurementStage,
    action: ReturnType<typeof setStage>
): ProcurementStage {
    return action.payload;
}

export default stage;