import React from 'react';
import './SuccessMessage.scss';
import { connect } from 'react-redux';
import { RootState } from 'PROCUREMENTTypes';
import { ProcurementStage, setStage } from '../../redux/actions/stage';
import { openSearch } from '../../redux/actions/search';
import Button from '../Button/Button';
import { withTranslation, WithTranslation } from 'react-i18next';

interface ISuccessMessageProps extends WithTranslation {
    stage: ProcurementStage;
    setStage: (stage: ProcurementStage) => void;
    openSearch: () => void;
}

export class SuccessMessage extends React.PureComponent<ISuccessMessageProps> {

    goToSearch = () => {
        this.props.setStage(ProcurementStage.SEARCH);
        this.props.openSearch();
    }

    render() {

        if (this.props.stage !== ProcurementStage.TICKET_CREATED) {
            return null;
        }

        return (
            <div className="success-message-ui">
                <div className="page-container">
                    <div className="message-container">
                        <img className="checked-img" src="/icons/checked.png" alt=""/>
                        <h2 className="title">
                          {this.props.t('thank_you', 'Vielen Dank!')}
                        </h2>
                        <div className="message-text">
                          {this.props.t('success_message', 'Wir haben Ihren Beschaffungswunsch entgegengenommen. Unsere Experten versuchen mit Hochdruck sich innerhalb der nächsten 48h bei Ihnen zu melden.')}
                        </div>
                    </div>
                    <Button onClick={this.goToSearch} size="large" className="go-home-btn">
                      {this.props.t('to_homepage', 'Zur Homepage')}
                    </Button>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: RootState) => {
	return {
        stage: state.stage
	};
};

const mapActions = {
    setStage,
    openSearch
};

export default connect(mapStateToProps, mapActions)(withTranslation()(SuccessMessage));
