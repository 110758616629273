import { createAsyncAction } from 'typesafe-actions';
import { UserData } from '../../../components/UserDataForm/UserDataForm';
import { ChosenProduct } from '../../actions/productsList';

export type CreateTicketRequestPayload = {
    userData: UserData,
    products: ChosenProduct[]
};

export const createTicketAsyncAction = createAsyncAction(
    '@bs/CREATE_TICKET_REQUEST [CMD]',
    '@bs/CREATE_TICKET_SUCCESS [EVENT]',
    '@bs/CREATE_TICKET_FAILURE [EVENT]'
)<CreateTicketRequestPayload, any, Error|Response>();