import {createReducer} from 'typesafe-actions';
import {Reducer} from 'redux';
import {Market, setMarket} from '../../actions/market';
import {getMarket} from '../../../utils/i18n';
import config from '../../../locale/config.json';

interface MarketConfigs {
  [key: string]: Market;
}

const currentMarket: string = getMarket();
const marketConfigs: MarketConfigs = config.marketConfig;
const currentMarketConfig = marketConfigs[currentMarket];

const market: Reducer<Market> = createReducer(currentMarketConfig).handleAction(setMarket, handleSetMarket);

function handleSetMarket(state: Market, action: ReturnType<typeof setMarket>) {
  return action.payload;
}

export default market;