import i18next from 'i18next';
import config from '../locale/config.json';

interface LocalObject {
  [key: string]: number;
}
interface MarketLinks {
  [key: string]: string;
}

interface MarketLinksCH {
  [key: string]: MarketLinks;
}

interface MarketObject {
  market: string;
  languages: string[];
  defaultLanguage: string;
  locale: LocalObject | number;
  brandId: number;
  shopId: string;
  productLink: string;
  marketLinks?: MarketLinks;
  marketLinksMultiLanguages?: MarketLinksCH;
}
interface MarketConfig {
  [key: string]: MarketObject;
}

let currentMarket:string | null = null;
const defaultMarket = 'de';
const defaultLanguage = 'de';
const defaultShopId = 'CQ_DE_B2B';

export const getMarket = () => {
  if (currentMarket) {
    return currentMarket;
  }

  const domain = window.location.host.split(':')[0];
  const domainParts = domain.split(/\.|-/);
  const marketConfig: MarketConfig = config.marketConfig;
  let market = defaultMarket;

  if ((domainParts.length >= 3) && marketConfig[domainParts[0]]) { // case subdomain
    market = domainParts[0];
  } else if (marketConfig[domainParts[domainParts.length - 1]]) { // case top level domain
    market = domainParts[domainParts.length - 1];
  }

  currentMarket = market;
  return market;
};

export const getDefaultLanguage = (): string => {
  return getMarketConfig()?.defaultLanguage || defaultLanguage;
};

export const getCurrentLanguage = () : string => {
  return i18next.language;
};

export const getCurrentLanguageForUrl = (): string => {
  const language= i18next.language;

  if (getMarket() === 'ch') {
    return language === 'fr-CH' ? 'fr' : 'de';
  }

  return language;
};

export const getCurrentMarketLanguages = () : string[] => {
  return getMarketConfig()?.languages || [];
};

export const getShopId = () : string => {
  return getMarketConfig()?.shopId || defaultShopId;
};

export const getProductLink = (productNumber: string) : string => {
  let productLink = getMarketConfig().productLink;
  productLink = productLink.replace('{productNumber}', productNumber);

  if (getMarket() === 'ch') {
    productLink = productLink.replace('{language}', getCurrentLanguageForUrl());
  }

  return productLink;
};

export const isDACHMarket = () :boolean => {
  const DACHMarkets = ['de', 'ch', 'at'];
  return DACHMarkets.includes(getMarket());
}

export const getCartDirectUrl = (productNumber: string, offerNumber: string) :string  => {
  return `https://www.conrad.${getMarket()}/${getCurrentLanguageForUrl()}/cart.redirectcart.html?p_b_a1=${productNumber}&p_b_o1=${offerNumber}&p_b_q1=1`;
}

export const getMarketLink = (id: string) : string => {
  let marketLinks: MarketLinks = getMarketConfig().marketLinks || {};

  if (getMarket() === 'ch') {
    const language = getCurrentLanguage() !== 'en' ? getCurrentLanguage() : 'de-CH';
    const marketLinksCH = getMarketConfig().marketLinksMultiLanguages || {};
    marketLinks = marketLinksCH[language];
  }

  return marketLinks[id] || '';
}

const getMarketConfig = (): MarketObject => {
  const market = getMarket();
  const marketConfig: MarketConfig = config.marketConfig;

  if (!marketConfig) {
    console.error('Market config is missing.');
  }

  return marketConfig[market];
};

export const getLocale = (): number => {
  const market = getMarket();
  const marketConfig: MarketConfig = config.marketConfig;
  if (market === 'ch') {
    const chLocales: LocalObject = marketConfig[market].locale as LocalObject;
    const locale = chLocales[getCurrentLanguage() === 'fr-CH' ? 'fr-CH' : 'de-CH'];
    return locale;
  }
  return marketConfig[market].locale as number;
};

export const getBrandId = () : number => {
  const market = getMarket();
  const marketConfig: MarketConfig = config.marketConfig;

  return marketConfig[market].brandId;
}

