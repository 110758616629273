import { takeEvery, call, getContext, put, select } from 'redux-saga/effects';
import { getAddressAsyncAction } from '../../actions/user';
import {getSession} from '../selectors';
import {SessionReducerState} from '../../reducers/session';

export function* watchGetAddress() {
    yield takeEvery(getAddressAsyncAction.request, getAddress);
}

function* getAddress(action: ReturnType<typeof getAddressAsyncAction.request>): Generator {
    try {
        const createConradUserAddressUrl: any = yield getContext('createConradUserAddressUrl');
        const session = yield select(getSession);
        const url: any = yield createConradUserAddressUrl((session as SessionReducerState).uid);
        const response: any = yield call(fetch, url, {
            credentials: 'include',
            headers: {'Accept': 'application/json'}
        });
        if (response.ok) {
            const data = yield response.json();
            yield put(getAddressAsyncAction.success(data));
            return;
        }

        yield put(getAddressAsyncAction.failure(response));
    } catch (err) {
        yield put(getAddressAsyncAction.failure(err));
    }
}
