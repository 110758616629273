import React from 'react';
import { connect } from 'react-redux';
import './ProductsList.scss';
import { RootState } from 'PROCUREMENTTypes';
import { removeProduct, setExpressToProduct, ChosenProduct } from '../../redux/actions/productsList';
import { openSearch } from '../../redux/actions/search';
import { ProcurementStage, setStage } from '../../redux/actions/stage';
import Button from '../Button/Button';
import { isSearchActive } from '../../selectors/search';
import {emptySearchState} from '../../redux/actions/search';
import { withTranslation, WithTranslation } from 'react-i18next';

// "Express" dependencies
// import Checkbox from '../Checkbox/Checkbox';
// import Tooltip from '../Tooltip/Tooltip';

interface IProcurementProps extends WithTranslation {
    products: ChosenProduct[];
    removeProduct: (key: number) => void;
    setExpressToProduct: (key: number, express: boolean) => void;
    stage: ProcurementStage;
    setStage: (stage: ProcurementStage) => void;
    search: boolean;
    openSearch: () => void;
    emptySearchState: () => void;
}

interface IProcurementState {
}

class ProductsList extends React.PureComponent<IProcurementProps, IProcurementState> {
    stagesWhereProductsAreMandatory: ProcurementStage[] = [ProcurementStage.PRDUCTS_LIST, ProcurementStage.USER_DATA_FORM];

    toggleExpress(product: ChosenProduct) {
        this.props.setExpressToProduct(product.key, !product.express);
    }

    removeProduct(product: ChosenProduct) {
        this.props.removeProduct(product.key);
    }

    showSearch = () => {
        this.props.setStage(ProcurementStage.SEARCH);
        this.props.openSearch();
    }

    showUserDataForm = () => {
        this.props.emptySearchState();
        this.props.setStage(ProcurementStage.USER_DATA_FORM);
    }

    openSearch = () => {
        this.props.openSearch();
    }

	render() {
        const {stage, products, search} = this.props;

        if (!products.length) {
            return null;
        }

		return (
            <div className="products-list-ui">
                <h3 className="title">
                  {this.props.t('your_request', 'Ihre Anfrage')}
                </h3>
                <div className="table-container">
                    <table className="product-table" cellSpacing="0">
                        <thead>
                            <tr className="products-header">
                                {/* <th className="express-th">
                                    Express
                                    <Tooltip
                                        placement="bottom-start"
                                        title="Benötigen Sie etwas besonders schnell? Markieren Sie den Artikel und wir werden soweit möglich die Dringlichkeit bei der Beschaffung berücksichtigen."
                                    />
                                </th> */}
                                <th className="quantity-col">
                                  {this.props.t('piece', 'Stück')}
                                </th>
                                <th className="img-col">
                                  {this.props.t('product', 'Produkt')}
                                </th>
                                <th className="product-details"></th>
                                {/* <th colSpan={2}>Max. Preis pro Stück</th> */}
                                <th className="remove-col"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {products.map(product => (
                                <tr className={'product-row' + (product.express ? ' express-product' : '')} key={product.key}>
                                    {/* <td className="espress-col">
                                        <Checkbox
                                            checked={Boolean(product.express)}
                                            onChange={() => this.toggleExpress(product)}
                                        />
                                    </td> */}
                                    <td className="quantity quantity-col">{product.quantity} <span className="grey">x</span></td>
                                    <td className="img-col">
                                        <img src={product.image || 'icons/no-image.png' } alt={product.title} className="product-img"/>
                                    </td>
                                    <td className="product-details">
                                        <div className="brand">{product.brand}</div>
                                        <div className="product-name">{product.title}</div>
                                    </td>
                                    {/* <td className="price">
                                        {product.maxUnitPrice.toFixed(2).replace('.', ',')} <span className="grey">€</span>
                                    </td> */}
                                    <td className="remove-col">
                                        <img
                                            src="icons/trash.svg"
                                            alt={this.props.t('delete', 'Löschen')}
                                            className="trash-icon"
                                            onClick={() => this.removeProduct(product)}
                                        />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div className="buttons-container">
                    {!search &&
                        <Button
                            onClick={this.openSearch}
                            className="further-article-btn"
                        >
                          {this.props.t('further_article', 'Weiterer Artikel')}
                        </Button>
                    }
                    {stage !== ProcurementStage.USER_DATA_FORM &&
                        <Button
                            onClick={this.showUserDataForm}
                            color="yellow"
                            className="show-user-data-btn"
                        >
                          {this.props.t('lock', 'Abschließen')}
                        </Button>
                    }
                </div>
            </div>
		);
	}
}

const mapStateToProps = (state: RootState): IProcurementProps => {
	return {
        products: state.productsList,
        stage: state.stage,
        search: isSearchActive(state).valueOr(false)
	} as IProcurementProps;
};

export default connect(mapStateToProps, { removeProduct, setExpressToProduct, setStage, openSearch, emptySearchState })(withTranslation()(ProductsList));
